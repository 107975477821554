import React, { useState, useEffect } from "react";
import { useInput, FieldTitle } from 'ra-core';
import { 
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core';

import axios from 'axios';
const FE_API_URL = `${process.env.REACT_APP_APIURL}:${process.env.REACT_APP_APIPORT}`

export default function NoRAStatusPicker({ statusType, label, ...props }) {

  const [choices, setChoices] = useState([]);
  const [choice, setChoice] = useState('');

  useEffect(() => {
    (async function() {
      try {
        let res = await axios.get(`${FE_API_URL}/dropdowns/${statusType}`);
        let options = res.data.map(option => {
          return (
            <MenuItem key={option.id} value={option.id}>
              {option.status}
            </MenuItem>
          )
        });
        setChoices(options);
      } catch(err) {
        console.log('Error fetching status options:', err);
      }
    })();
  }, [])

  function handleChange(evt){
    setChoice(evt.target.value);
  }

  return (
      <Select
        labelId={`status-picker-${label}-label`}
        id={`status-picker-${label}`}
        value={choice}
        onChange={handleChange}
        label={label}
        {...props}
        >
        {choices.length ? choices : null}
      </Select>
  )
};

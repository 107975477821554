// https://marmelab.com/blog/2019/02/07/react-admin-advanced-recipes-creating-and-editing-a-record-from-the-list-page.html
import React, { useRef, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  SelectInput,
  TextInput,
  Filter,
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  useNotify,
  useRefresh,
  FunctionField,
  useRedirect,
  DatagridBody,
  CardActions, 
  ShowButton,
  ReferenceManyField,
  DateField,
  EditButton
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { 
  withStyles, 
  Button, 
  Accordion, 
  Card, 
  CardHeader, 
  CardContent,
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';

import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddIcon from '@material-ui/icons/Add';


import ShotEdit from './ShotEdit';
import { MyListActions } from './MyListActions';
import MyDatagridRow from './MyDataGridRow';
import NotesInput from './NotesInput';
import IssueList from './components/IssueList';
import CopyFilename from './components/CopyFilename';

import AssignToButton from './components/AssignToButton';
import { rowConditionalStyle } from './layout/commonStyles';
import qs from 'qs';


import {
  ESEHPagination,
  UrlFieldShort,
  defaultPerPage,
  useRecursiveTimeout,
  userQcQuickMap,
  tkdnMap,
  getRefreshMillis,
} from './ESEHParts';

const QualityControlSearchFilter = (props) => {
  const { holeselect, ...rest } = props;
  const holeSelect = JSON.parse(holeselect);

  return (
    <Filter {...props}>
      <TextInput label="Search" source="_fullRowQuery" alwaysOn />
      <TextInput
        label="Record ID Search"
        source="_Record_ID_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Player ID Search"
        source="_Player_ID_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Round Search"
        source="_Round_Search_Term"
        defaultValue=""
      />
      {holeSelect && (
        <TextInput
          label="Hole Search"
          source="_Hole_Search_Term"
          defaultValue=""
        />
      )}
      <TextInput
        label="Stroke Search"
        source="_Stroke_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Player Name Search"
        source="_Player_Name_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="QC Status Search"
        source="_QC_Status_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Takedown Status Search"
        source="_TKDN_Status_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Shot Date Time Search"
        source="_Shot_Date_Time_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="QC By Search"
        source="_QC_By_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="QC Note Search"
        source="_Notes_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Hawk-Eye Note Search"
        source="_Hawkeye_Notes_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Masters Link Search"
        source="_IBM_Link_Term"
        defaultValue=""
      />
    </Filter>
  );
};

const MyDatagridBody = (props) => (
  <DatagridBody
    {...props}
    row={<MyDatagridRow expandedid={props.expandedid} />}
  />
);
const MyDatagrid = (props) => (
  <Datagrid
    rowStyle={rowConditionalStyle}
    {...props}
    body={<MyDatagridBody expandedid={props.expandedid} />}
  />
);

export const QualityControlList = (props_) => {
  // console.log('QC Props:', props_);
  const savedDoRefresh = useRef(true);
  const expandedid = useRef({});
  const props = { expandedid, ...props_ };
  const refresh = useRefresh();
  useRecursiveTimeout(
    () => savedDoRefresh.current && refresh(),
    getRefreshMillis
  );
  const pageBanner = JSON.parse(
    sessionStorage.getItem('__active_screen') || '""'
  );
  let filterObj = {};
  Object.keys(userQcQuickMap).forEach((key) => {
    const filterName = `_qcfilter_${key}`;
    const fromLoc = sessionStorage.getItem(filterName);
    const parsed = JSON.parse(fromLoc);
    //console.log({ filterName, fromLoc, parsed });
    if (parsed) {
      filterObj[filterName] = parsed;
    }
  });
  Object.keys(tkdnMap).forEach((key) => {
    const filterName = `_qcfilter_${key}`;
    const fromLoc = sessionStorage.getItem(filterName);
    const parsed = JSON.parse(fromLoc);
    //console.log({ filterName, fromLoc, parsed });
    if (parsed) {
      filterObj[filterName] = parsed;
    }
  });
  const __qchole = sessionStorage.getItem('__qchole')
    ? JSON.parse(sessionStorage.getItem('__qchole')).__qchole
    : '';
  if (__qchole) {
    filterObj['__qchole'] = __qchole;
  }

  return (
    <React.Fragment key={pageBanner}>
      <Typography
        style={{ position: 'absolute', transform: 'translateY(1em)' }}
        variant="h6"
        id={pageBanner}
      >
        {pageBanner}
      </Typography>
      <List
        {...props}
        filter={filterObj}
        filters={
          <QualityControlSearchFilter holeselect={JSON.stringify(!__qchole)} />
        }
        bulkActionButtons={false}
        perPage={defaultPerPage}
        actions={<MyListActions />}
        pagination={<ESEHPagination />}
        sort={{ field: 'updatedAt', order: 'DESC' }}
        title=" "
      >
        <MyDatagrid
          rowClick="expand"
          expand={(
            <ShotEdit
              onMount={() => {savedDoRefresh.current = false;}}
              onUnmount={() => {savedDoRefresh.current = true;}}
              {...props}
            />)}
          expandedid={expandedid}
        >
          <TextField label="Record ID" source="RecordID" />
          <TextField label="Player ID" source="PlayerID" />
          <NumberField label="Round" source="Round" />
          <NumberField label="Hole" source="Hole" />
          <NumberField label="Stroke" source="Stroke" />
          <TextField label="Player Name" source="Player Name" />
          <TextField label="QC Status" source="QcStatusString" />
          <TextField label="Takedown Status" source="TakedownStatusString" />
          <TextField label="Shot" source="Shot Date Time" />
          <TextField label="QC By" source="QcBy" />
          {/* <TextField label="Note" source="Notes" /> */}
          <FunctionField
            label="QC Note"
            source="Notes"
            sortable={true}
            render={(rec) => (rec && rec.Notes ? rec.Notes.substr(0, 35) : '')}
          />
          <FunctionField
            label="Hawk-Eye Note"
            source="Hawkeye Notes"
            sortable={true}
            render={(rec) =>
              rec && rec.HawkeyeNotes ? rec.HawkeyeNotes.substr(0, 35) : ''
            }
          />
          <UrlFieldShort label="Masters.com" source="IbmLink" />
        </MyDatagrid>
      </List>
    </React.Fragment>
  );
  
};


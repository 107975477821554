

import React, { useRef, useState, useEffect } from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  SelectInput,
  TextInput,
  Filter,
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  useNotify,
  useRefresh,
  FunctionField,
  useRedirect,
  DatagridBody,
  useUpdate,
  useDataProvider,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { 
  withStyles, 
  Button, 
  Accordion, 
  Card, 
  CardHeader, 
  CardContent,
  Divider,
  Fab,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
//   TextField,
  Tooltip
} from '@material-ui/core';

import axios from 'axios';



const ESEH_API_URL = process.env.REACT_APP_ESEH_API_URL;

function AssignToButton(props) {
    let {record} = props;
    const userEmail = JSON.parse(localStorage.getItem('user-email') || '""');
    const refresh = useRefresh();

    let handleAssignToMe = async function(evt) {
        const options = {
            method: 'PUT',
            url: `${ESEH_API_URL}/files/status/${record.id}`,
            headers: {'Content-Type': 'application/json'},
            data: {Producing: true, User: userEmail}
          };
        await axios.request(options);
        refresh();
    };

    let handleRemoveAssignment = async function(evt) {
        const options = {
            method: 'PUT',
            url: `${ESEH_API_URL}/files/status/${record.id}`,
            headers: {'Content-Type': 'application/json'},
            data: {Producing: false, User: userEmail}
          };
        await axios.request(options);
        refresh();
    }


    return (
        <div {...props} style={{display: 'flex', justifyContent: 'center', alignContent: 'center', flex: 1}}>
        {record.AssignedTo !== userEmail ? 
            <Button  onClick={handleAssignToMe}>
                Assign to me
            </Button> : 
            <Button onClick={handleRemoveAssignment}>
                Remove Assignment
            </Button>
        }
        </div>
    );
}

export default AssignToButton
import * as React from "react";
import { useInput, FieldTitle } from 'ra-core';
import { useQuery, Loading, Error, SelectInput } from 'react-admin';


export const StatusPicker = ({ statusType, label, source, style, disabled }) => {

  const { data, loading, err } = useQuery({ 
      type: 'getOne',
      resource: 'dropdowns',
      payload: { id: statusType }
  });

  //if (loading) return null;
  //if (err) return null;
  //if (!data) return null;

  return (
    <SelectInput
        style={style}
        label={label}
        disabled={disabled || loading ? true : false}
        source={source}
        choices={data ? data.map(c => {
          return {id: c.id, name: c.status}
        }):[]}
    />
  )
};
//This can be nested in a list to pass the data back to the parent prop.... react admin sucks. 
import React, { useState } from 'react';
import {
  useListContext
} from 'react-admin';

export const ListDataGetter = (props) => {
  const { data, selectedIds, filterValues, total } = useListContext()
  React.useEffect(() => {
    props.setListData({data, selectedIds, filterValues, total})  
  }, [ data, selectedIds, filterValues, total])
  return (
    <></>
  )
}
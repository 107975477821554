import React, { useRef, useState, useEffect } from 'react';
import { 
  withStyles, 
  Button, 
  Accordion, 
  Card, 
  CardHeader, 
  CardContent,
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';

import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddIcon from '@material-ui/icons/Add';

import axios from 'axios';
import _ from 'lodash';
import { RoutesWithLayout } from 'ra-core';

const ESEH_API_URL =   process.env.REACT_APP_ESEH_API_URL; // 'http://mcclellan.tv:5001/api/v1';
// const FE_API_URL = `${process.env.REACT_APP_APIURL}:${REACT_APP_APIPORT}`;

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const tableColors = ['#6db1bf', '#eacdc2', '#d1efb5', '#f97068', '#d999b9'];

function HistoryRow(props) {
    const { row, fileList } = props;
    let k = fileList.indexOf(row.FileID);
    k = k % tableColors.length;
    let createdAt = row.createdAt || new Date().toISOString();
    let [day, time] = createdAt.split('T');
    let editTimeCell = (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <span>{day}</span>
        <span>{time.slice(0,-5)}</span>
      </div>
    );

    let theIssues = row.issues && row.issues.length ? row.issues.map(r => <li key={r.id}>{r.Issue}</li>): [];

    let issuesCell = (<ul>{theIssues}</ul>)
    
  return (
    <TableRow key={row.id}>
      <TableCell component="th" scope="row" style={{backgroundColor: tableColors[k]}}>
        {''}
      </TableCell>
      <TableCell align="right">{row.FileID === fileList[0] ? row.FileID + '(current)': row.FileID}</TableCell>
      <TableCell align="right">{editTimeCell}</TableCell>
      <TableCell align="right">{row.QcStatus}</TableCell>
      <TableCell align="right">{row.TakedownStatus}</TableCell>
      <TableCell align="right">{row.AssignedTo}</TableCell>
      <TableCell align="left">{issuesCell}</TableCell>
    </TableRow>
  );
} 

function filterUniqBy(arr, keyList) {
  let newArr = _.map(arr, (el) => _.pick(el, keyList)).filter(obj => obj.createdAt);
  newArr = _.sortBy(_.sortBy(newArr, 'createdAt'), 'FileID');
  let list = _.uniqWith(newArr, (f1, f2) => _.isEqual(f1, f2));
  return list;
}

function processRowData(record, history, issues) {
  let fileList = filterUniqBy(history.files, ['FileID', 'createdAt']);
  let smtList = filterUniqBy(history.smt, ['FileID', 'assignedTo', 'createdAt']);
  let qcList = filterUniqBy(history.qc, ['FileID', 'HawkeyeNotes', 'Notes', 'QcStatus', 'TakedownStatus', 'createdAt']);
  let issueList = filterUniqBy(issues, ['FileID', 'createdBy', 'createdAt', 'Issue', 'IssueType', 'ResolveMessage', 'Resolution'])

  let processArr = [fileList, smtList, qcList, issueList];

  let resultArray = [];
  while (processArr.some(arr=>arr.length)) {
    let newIssueFlag = false;

    if (processArr.some(arr => !arr.length)) {
      processArr = processArr.filter(arr => arr.length);
    }
    console.log(`Processing lists: ${processArr.map(arr => arr.length).join(', ')} => {${resultArray.length}}`);
    let firstItems = processArr.map(arr => arr[0] && arr[0].createdAt);
    let minCreatedAt = firstItems.sort()[0];
    let indexOfList = firstItems.indexOf(minCreatedAt);
    if (indexOfList === -1) {
      console.log('No min found.', processArr.map(arr => arr[0]));
    }
    if (firstItems[indexOfList] === issueList[0]) {
      console.log(`Adding issue`);
      let nextRow = Object.assign({}, resultArray[resultArray.length - 1]);
      if (!nextRow.issues) nextRow.issues = [];
      resultArray.issues.push(issueList.shift());
      continue;
    }
    if (!resultArray.length) {
      resultArray.push(processArr[indexOfList].shift());
      continue;
    }
    let prev = resultArray[resultArray.length - 1];
    let newObj = Object.assign({}, prev, processArr[indexOfList].shift());
    
    resultArray.push(newObj)
  }
  resultArray = _.uniqWith(resultArray, _.isEqual);

  resultArray = _.sortBy(resultArray, 'fileID');

  return resultArray;
}

export default function ShotVersionHistory(props) {
  const { record, } = props;

  const [rowData, setRowData] = useState([]);

  useEffect(() =>{

    (async () => {
      // console.log('Getting issues...', record);
      
      let historyProm = axios.get(`${ESEH_API_URL}/history/${record.id}`);
      let issuesProm = axios.get(`${ESEH_API_URL}/issues/byRecordID/${record.id}`)
      const [historyRes, issuesRes] = await Promise.all([historyProm, issuesProm]);
      const history = historyRes.data;
      const issues = issuesRes.data;
      console.log('Got history data:', history, issues);


      const newRowData = processRowData(record, history, issues);
      setRowData(newRowData);
    })()
    
  }, []);

  let fileList = _.uniq(rowData.map(r => r.FileID)).sort();

  let rows = rowData.map((row, idx) => <HistoryRow row={row} key={idx} fileList={fileList} />);



  const myTable = (
    <TableContainer component={Paper} style={{padding: '10px'}}>
      <h3>Record {props.record.RecordID} Version History</h3>
      <Table stickyHeader className={'historytable'} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{''}</TableCell>
            <TableCell align="right">Video File</TableCell>
            <TableCell align="right">Edit Time:</TableCell>
            <TableCell align="right">QC Status</TableCell>
            <TableCell align="right">Takedown Status</TableCell>
            <TableCell align="center">Assigned To:</TableCell>
            <TableCell align="center">Issues</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return myTable;



}
  


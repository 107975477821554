import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import copy from 'copy-to-clipboard';

function CopyFilename(props) {
    let {record} = props;
    const [wasCopied, setWasCopied] = useState(false)

    let handleClick = function(evt) {
        const dateStr = String(Number(new Date())).slice(0,-3);
        if (record.FileID) {
            const baseFileID = record.FileID.slice(0, record.FileID.lastIndexOf('_'))
            copy(`${baseFileID}_${dateStr}`)
        } else {
            copy(`${record.RecordID}_${dateStr}`)
        }
        setWasCopied(true);
        setTimeout(() => setWasCopied(false), 3000);
    }

    return (
        <div style={props.style || {}}>
            <Button onClick={handleClick}>
                Copy New Filename
            </Button>
            {wasCopied && <span style={{color: 'red'}}>Copied a new filename to clipboard!</span>}
        </div>
    );
}

export default CopyFilename
import * as React from "react";
import { useQuery, Loading, Error, SelectInput } from 'react-admin';
import ErrorOutline from '@material-ui/icons/ErrorOutline';

export const IconikProxy = (props) => {
  const { iconikAssetID } = props
  
  const { data, loading, err } = useQuery({ 
    type: 'getOne',
    resource: `iconik/proxie`,
    payload: { id: iconikAssetID ? iconikAssetID : 'nope'}
  })
  
  

  React.useEffect(() => {
    console.log('iconik proxy response')
    console.log(data)
  }, [data])

  
  return (
    loading ? 
      <Loading />
    :
      data.url ? 
        <video src={`${data.url}`} controls controlsList="nodownload" width="100%" ></video>
      :
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}>
          <ErrorOutline />
          <div>No file found in iconik</div>
        </div>
  )
}


// let yellowRowColor = '#B39C4D';
// let redRowColor = '#AF1D35';

let yellowRowColor = '#726331';
let redRowColor = '#7C1326';

export const rowConditionalStyle = (record, index) => {
  if (record.TapIn) {
    return ({backgroundColor: record.TapIn? yellowRowColor : '#424242'})
  } else {
    return ({backgroundColor: record.StrokeType === 'Normal'? '#424242' : redRowColor})
  }
};


import * as React from 'react';
import { useInput, FieldTitle } from 'ra-core';

import { ResettableTextField, InputHelperText } from 'react-admin';

export const NotesReadOnly = ({
  label,
  format,
  helperText,
  onBlur,
  onFocus,
  onChange,
  options,
  parse,
  resource,
  source,
  validate,
  ...rest
}) => {
  const {
    id,
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'text',
    validate,
    ...rest,
  });

  return (
    <ResettableTextField
      rows={5}
      id={id}
      {...input}
      label={
        label !== '' &&
        label !== false && (
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        )
      }
      error={!!(touched && error)}
      helperText={
        <InputHelperText
          touched={touched}
          error={error}
          helperText={helperText}
        />
      }
      {...options}
      {...rest}
    />
  );
};


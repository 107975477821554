import React, { useRef } from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  FunctionField,
  TextInput,
  Filter,
  useRefresh,
  SelectInput,
  useNotify,
  SimpleForm,
  Toolbar,
  SaveButton,
  Edit,
  DatagridBody,
} from 'react-admin';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {
  ESEHPagination,
  UrlFieldShort,
  defaultPerPage,
  useRecursiveTimeout,
  getRefreshMillis,
} from './ESEHParts';
import { MyListActions } from './MyListActions';
import NotesInput from './NotesInput';
import MyDatagridRow from './MyDataGridRow';
import ShotEdit from './ShotEdit'
import { rowConditionalStyle } from './layout/commonStyles';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const MyDatagridBody = (props) => (
  <DatagridBody
    {...props}
    row={<MyDatagridRow expandedid={props.expandedid} />}
  />
);
const MyDatagrid = (props) => (
  <Datagrid
    rowStyle={rowConditionalStyle}
    {...props}
    body={<MyDatagridBody expandedid={props.expandedid} />}
  />
);

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton label="SAVE" redirect="list" />
  </Toolbar>
));

const ShotsSearchFilter = (props) => {
  const shotViewSelector = `shots_${JSON.parse(
    sessionStorage.getItem('_shotIdx_') || '""'
  )}`;
  //console.log({ svs: shotViewSelector });
  const filterSet = (
    <Filter {...props}>
      <TextInput label="Search" source="_fullRowQuery" alwaysOn />
      <TextInput
        label="Record ID Search"
        source="_Record_ID_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Round Search"
        source="_Round_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Hole Search"
        source="_Hole_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Stroke Search"
        source="_Stroke_Search_Term"
        defaultValue=""
      />
      {['shots_2', 'shots_3', 'shots_4', 'shots_5'].includes(
        shotViewSelector
      ) && (
        <TextInput
          label="TapIn Search"
          source="_TapIn_Search_Term"
          defaultValue=""
        />
      )}
      {['shots_4', 'shots_7'].includes(shotViewSelector) && (
        <TextInput
          label="Xsq Total Process Time"
          source="_XsqTotalProcessTime"
          defaultValue=""
        />
      )}
      {['shots_6', 'shots_7', 'shots_1'].includes(shotViewSelector) && (
        <TextInput
          label="Player ID Search"
          source="_Player_ID_Search_Term"
          defaultValue=""
        />
      )}
      <TextInput
        label="Player Name Search"
        source="_Player_Name_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="File Name Search"
        source="_File_Name_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Shot Date Time Search"
        source="_Shot_Date_Time_Search_Term"
        defaultValue=""
      />
      {['shots_1'].includes(shotViewSelector) && (
        <TextInput
          label="QC Status Search"
          source="_QC_Status_Search_Term"
          defaultValue=""
        />
      )}
      {['shots_1'].includes(shotViewSelector) && (
        <TextInput
          label="Takedown Status Search"
          source="_TKDN_Status_Search_Term"
          defaultValue=""
        />
      )}
      {['shots_1'].includes(shotViewSelector) && (
        <TextInput
          label="QC Note Search"
          source="_Notes_Search_Term"
          defaultValue=""
        />
      )}
      {['shots_1'].includes(shotViewSelector) && (
        <TextInput
          label="QC By Search"
          source="_QC_By_Search_Term"
          defaultValue=""
        />
      )}
      {['shots_2', 'shots_3', 'shots_4', 'shots_5'].includes(
        shotViewSelector
      ) && (
        <TextInput
          label="XSquare Found File"
          source="_XTAccess1_Search_Term"
          defaultValue=""
        />
      )}
      {['shots_2', 'shots_3', 'shots_4', 'shots_5'].includes(
        shotViewSelector
      ) && (
        <TextInput
          label="XSquare Start xCode"
          source="_XTAccess2_Search_Term"
          defaultValue=""
        />
      )}
      {['shots_2', 'shots_3', 'shots_4', 'shots_5'].includes(
        shotViewSelector
      ) && (
        <TextInput
          label="XSquare Finish xCode"
          source="_XTAccess3_Search_Term"
          defaultValue=""
        />
      )}
      {['shots_2', 'shots_3', 'shots_4', 'shots_5'].includes(
        shotViewSelector
      ) && (
        <TextInput
          label="XSquare Move File"
          source="_XTAccess4_Search_Term"
          defaultValue=""
        />
      )}
      {['shots_1', 'shots_2', 'shots_4', 'shots_5', 'shots_7'].includes(
        shotViewSelector
      ) && (
        <TextInput
          label="IBM Posted time"
          source="_IbmPostedTime_Search_Term"
          defaultValue=""
        />
      )}
      {['shots_4', 'shots_5', 'shots_7'].includes(shotViewSelector) && (
        <TextInput
          label="Masters Link Search"
          source="_IBM_Link_Term"
          defaultValue=""
        />
      )}
      {['shots_5'].includes(shotViewSelector) && (
        <TextInput
          label="XSquare To IBM Post Time"
          source="_HawkeyeClipToIbmOnWeb_Link_Term"
          defaultValue=""
        />
      )}
      {['shots_5'].includes(shotViewSelector) && (
        <TextInput
          label="Shot Time to IBM on Web"
          source="_TimeToIbmPost_Link_Term"
          defaultValue=""
        />
      )}
      {['shots_4'].includes(shotViewSelector) && (
        <TextInput
          label="Shot Time to Found File"
          source="_ShotTimeToXsqFoundFile_Link_Term"
          defaultValue=""
        />
      )}
      {['shots_4', 'shots_7'].includes(shotViewSelector) && (
        <TextInput
          label="Shot Delay"
          source="_StrokeTimeVsSmtCreateTime_Link_Term"
          defaultValue=""
        />
      )}
    </Filter>
  );
  return filterSet;
};

// const NoteEdit = (props_) => {
//   const { onMount, onUnmount, basePath, ...props } = props_;
//   navigator.clipboard.writeText(props.id);
//   const refresh = useRefresh();
//   React.useEffect(() => {
//     //Nothing on mount.
//     // console.log({ MOUNTING: { props } });
//     onMount && onMount();
//     return () => {
//       //console.log('UNMOUNTING');
//       onUnmount && onUnmount();
//     };
//   }, [onMount, onUnmount]);
//   const notify = useNotify();
//   //const redirect = useRedirect();
//   const onSuccess = ({ data }) => {
//     // console.log({ UPDATE_SUCCESS: data });
//     notify(
//       'Update Successful!',
//       'info',
//       {
//         smart_count: 1,
//       },
//       props.undoable
//     );
//     // console.log({ propunex: props.expandedid });
//     const doUnex = props.expandedid.current.unexpand;
//     props.expandedid.current = {};
//     doUnex && doUnex();
//     //redirect('list', props.basePath, data.id, data);
//     refresh();
//   };

//   const onFailure = (error) => {
//     // console.log({ UPDATE_FAIL: error });
//     notify(
//       'Update Failed!',
//       'info',
//       {
//         smart_count: 1,
//       },
//       props.undoable
//     );
//     //redirect('list', props.basePath, data.id, data);
//     refresh();
//   };

//   //console.log({ EditProps: props });
//   const username = JSON.parse(localStorage.getItem('username') || '""');
//   const userEmail = JSON.parse(localStorage.getItem('user-email') || '""');
//   //const qcEditBy = `${username} <${userEmail}>`;
//   const qcEditBy = `${userEmail}`;
//   const editDate = new Date().toISOString();
//   //console.log({ editDate });
//   return (
//     <Edit
//       //onSuccess={onSuccess}
//       onSuccess={onSuccess}
//       onFailure={onFailure}
//       {...props}
//       basePath={basePath}
//       undoable={false}
//       redirect="list"
//       /* disable the app title change when shown */
//       title=" "
//     >
//       <SimpleForm toolbar={<CustomToolbar />}>
//         <div style={{ width: '100%', height: '100%', display: 'table' }}>
//           <div
//             style={{
//               width: '100%',
//               height: '100%',
//               display: 'table-row',
//             }}
//           >
//             <div
//               style={{
//                 width: '30%',
//                 paddingRight: '2ch',
//                 height: '100%',
//                 display: 'table-cell',
//               }}
//             >
//               <div
//                 style={{
//                   width: '100%',
//                   height: '100%',
//                   display: 'table-row',
//                 }}
//               >
//                 <div
//                   style={{
//                     height: '100%',
//                     width: '50%',
//                     paddingRight: '2ch',
//                     display: 'table-cell',
//                   }}
//                 >
//                   <SelectInput
//                     label="QC Status"
//                     source="QcStatus"
//                     style={{ width: '200px' }}
//                     choices={[
//                       { id: '-2', name: 'Unproducible' },
//                       { id: '-1', name: 'Pass - Resent' },
//                       { id: '0', name: 'Pass' },
//                       { id: '1', name: 'Fail - no audio' },
//                       { id: '2', name: 'Fail - spectator talking' },
//                       { id: '3', name: 'Fail - audio sync' },
//                       { id: '4', name: 'Fail - audio tech issue' },
//                       { id: '5', name: 'Fail - other' },
//                       { id: '6', name: 'Fail - Editorial' },
//                       { id: '8', name: 'Fail - Hawkeye' },
//                       //{ id: '7', name: 'Fail - Takedown' },
//                       //{ id: '9', name: 'Takedown-Complete' },
//                     ]}
//                   />
//                 </div>
//                 <div
//                   style={{
//                     height: '100%',
//                     width: '50%',
//                     display: 'table-cell',
//                   }}
//                 >
//                   <SelectInput
//                     label="Takedown Status"
//                     source="takedownStatus"
//                     style={{ width: '200px' }}
//                     choices={[
//                       { id: '-1', name: 'No Takedown' },
//                       { id: '0', name: 'Takedown' },
//                       { id: '1', name: 'Takedown Complete' },
//                     ]}
//                   />
//                 </div>
//               </div>
//               <div>
//                 <TextInput
//                   disabled
//                   label="Edit By"
//                   source="editQcBy"
//                   initialValue={qcEditBy}
//                 />
//               </div>
//               <div>
//                 <TextInput
//                   disabled
//                   label="Edit Time"
//                   source="editAt"
//                   initialValue={editDate}
//                 />
//               </div>
//             </div>
//             <div>
//               <NotesInput
//                 style={{
//                   width: '45%',
//                   height: '50%',
//                 }}
//                 multiline
//                 id="Notes"
//                 label="QC Note"
//                 resettable={false}
//                 source="Notes"
//               />
//               <NotesInput
//                 style={{
//                   width: '45%',
//                   height: '50%',
//                 }}
//                 multiline
//                 id="HawkeyeNotes"
//                 source="HawkeyeNotes"
//                 label="Hawkeye Note"
//                 resettable={false}
//               />
//               <div
//                 style={{
//                   width: '5%',
//                   height: '100%',
//                   display: 'inline-block',
//                 }}
//               />
//               <NotesInput
//                 style={{
//                   width: '45%',
//                   height: '100%',
//                 }}
//                 multiline
//                 id="HawkeyeNotes"
//                 source="HawkeyeNotes"
//                 label="Hawkeye Note"
//                 resettable={false}
//               />
//             </div>
//           </div>
//         </div>
//       </SimpleForm>
//     </Edit>
//   );
// };

export const ShotList = (props_) => {
  const savedDoRefresh = useRef(true);
  const expandedid = useRef({});
  const props = { expandedid, ...props_ };
  const refresh = useRefresh();

  const doExpand = (props) => {
    return (
      <ShotEdit
        onMount={() => {
          savedDoRefresh.current = false;
        }}
        onUnmount={() => {
          savedDoRefresh.current = true;
        }}
        {...props}
      />
    );
  };
  const pageBanner = JSON.parse(
    sessionStorage.getItem('__active_screen') || '""'
  );
  const shotViewSelector = `shots_${JSON.parse(
    sessionStorage.getItem('_shotIdx_') || '""'
  )}`;
  useRecursiveTimeout(
    () =>
      (savedDoRefresh.current && shotViewSelector !== 'shots_6' && refresh()) ||
      (savedDoRefresh.current && refresh()),
    getRefreshMillis
  );
  const roundViewSelector = JSON.parse(
    sessionStorage.getItem('__sround') || '""'
  );
  let shotFilter = {};
  if (shotViewSelector) {
    shotFilter['_shotIdx_'] = shotViewSelector;
  }
  if (roundViewSelector) {
    shotFilter['__sround'] = roundViewSelector;
  }
  //console.log({ refresh: true, shotViewSelector });
  const shotList = (
    <React.Fragment>
      <Typography
        style={{ position: 'absolute', transform: 'translateY(1em)' }}
        variant="h6"
        id={pageBanner}
      >
        {pageBanner}
      </Typography>
      <List
        {...props}
        actions={<MyListActions />}
        filter={shotFilter}
        filters={<ShotsSearchFilter />}
        bulkActionButtons={false}
        perPage={defaultPerPage}
        pagination={<ESEHPagination />}
        sort={{ field: 'updatedAt', order: 'DESC' }}
        title=" "
      >
        <MyDatagrid
          rowClick={
            shotViewSelector === 'shots_6'
              ? 'expand'
              : (elem) => navigator.clipboard.writeText(elem)
          }
          expand={shotViewSelector === 'shots_6' && doExpand(props)}
          expandedid={expandedid}
        >
          <TextField label="Record ID" source="RecordID" />
          <NumberField label="Round" source="Round" />
          <NumberField label="Hole" source="Hole" />
          <NumberField label="Stroke" source="Stroke" />
          {['shots_2', 'shots_3', 'shots_4', 'shots_5'].includes(
            shotViewSelector
          ) && <NumberField label="Tap-in" source="TapIn" />}
          {['shots_4', 'shots_7'].includes(shotViewSelector) && (
            <TextField
              label="Xsq Total Process Time"
              source="XsqTotalProcessTime"
            />
          )}
          {['shots_6', 'shots_7', 'shots_1'].includes(shotViewSelector) && (
            <TextField label="Player ID" source="Player" />
          )}
          <TextField label="Player Name" source="Player Name" />
          <TextField label="File Name" source="File Name" />
          <TextField label="Shot" source="Shot Date Time" />
          {['shots_1'].includes(shotViewSelector) && (
            <NumberField label="QC Status" source="QcStatusString" />
          )}
          {['shots_1'].includes(shotViewSelector) && (
            <NumberField
              label="Takedown Status"
              source="TakedownStatusString"
            />
          )}
          {['shots_1'].includes(shotViewSelector) && (
            <FunctionField
              label="QC Note"
              source="Notes"
              sortable={true}
              render={(rec) =>
                rec && rec.Notes ? rec.Notes.substr(0, 35) : ''
              }
            />
          )}
          {/* {['shots_1'].includes(shotViewSelector) && (
            <TextField
              label="QC By"
              render={(rec) =>
                rec && rec.QC_By ? rec.QC_By.replace(/<.*>/, '') : ''
              }
            />
          )} */}
          <TextField label="QC By" source="QC By" />
          {['shots_2', 'shots_3', 'shots_4', 'shots_5'].includes(
            shotViewSelector
          ) && <TextField label="Hawk-Eye" source="HawkeyeWriteFile" />}
          {['shots_2', 'shots_3', 'shots_4', 'shots_5'].includes(
            shotViewSelector
          ) && <TextField label="Exported" source="XSquare Found File" />}
          {['shots_2', 'shots_3', 'shots_4', 'shots_5'].includes(
            shotViewSelector
          ) && (
            <TextField label="Transcode Start" source="XSquare Start xCode" />
          )}
          {['shots_2', 'shots_3', 'shots_4', 'shots_5'].includes(
            shotViewSelector
          ) && <TextField label="Transcoded" source="XSquare Finish xCode" />}
          {['shots_2', 'shots_3', 'shots_4', 'shots_5'].includes(
            shotViewSelector
          ) && <TextField label="Pushed" source="XSquare Move File" />}
          {['shots_1', 'shots_2', 'shots_4', 'shots_5', 'shots_7'].includes(
            shotViewSelector
          ) && <TextField label="Posted" source="IBM Posted" />}
          {['shots_5'].includes(shotViewSelector) && (
            <TextField
              label="Transcoded to Posted"
              source="XSquare To IBM Post Time"
            />
          )}
          {['shots_5'].includes(shotViewSelector) && (
            <TextField label="SMT to Posted" source="Shot time to IBM on web" />
          )}
          {['shots_4'].includes(shotViewSelector) && (
            <TextField
              label="SMT to Transcode"
              source="Shot Time to Found File"
            />
          )}
          {['shots_4', 'shots_7'].includes(shotViewSelector) && (
            <TextField
              label="Shot Delay"
              source="Shot Real Time v SMT Created Time"
            />
          )}
          {['shots_4', 'shots_5', 'shots_7'].includes(shotViewSelector) && (
            <UrlFieldShort label="Masters.com" source="IBM Website Link" />
          )}
        </MyDatagrid>
      </List>
    </React.Fragment>
  );
  return shotList;
};

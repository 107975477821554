import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DatagridBody,
  TextInput,
  Filter,
  useRefresh,
  SelectInput,
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  useNotify,
  useRedirect,
} from 'react-admin';
import { useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import {
  ESEHPagination,
  UrlFieldShort,
  defaultPerPage,
  useRecursiveTimeout,
  getRefreshMillis,
} from './ESEHParts';
import { MyListActions } from './MyListActions';
import ShotEdit from './ShotEdit'
import MyDatagridRow from './MyDataGridRow';
import { withStyles } from '@material-ui/core';
import NotesInput from './NotesInput';
import MyDataGrid from './MyDataGrid';
import { rowConditionalStyle } from './layout/commonStyles';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const MyDatagridBody = (props) => (
  <DatagridBody
    {...props}
    row={<MyDatagridRow expandedid={props.expandedid} />}
  />
);

const MyDatagrid = (props) => (
  <MyDataGrid
    rowStyle={rowConditionalStyle}
    {...props}
    body={<MyDatagridBody expandedid={props.expandedid} />}
  />
);

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton label="SAVE" redirect="list" />
  </Toolbar>
));

// const NoteEdit = (props_) => {
//   const { onMount, onUnmount, ...props } = props_;
//   navigator.clipboard.writeText(props.id);
//   const refresh = useRefresh();
//   React.useEffect(() => {
//     //Nothing on mount.
//     // console.log({ MOUNTING: { props } });
//     onMount && onMount();
//     return () => {
//       // console.log('UNMOUNTING');
//       onUnmount && onUnmount();
//     };
//   }, [onMount, onUnmount]);
//   const notify = useNotify();
//   const redirect = useRedirect();
//   const onSuccess = ({ data }) => {
//     // console.log({ UPDATE_SUCCESS: data });
//     notify(
//       'Update Successful!',
//       'info',
//       {
//         smart_count: 1,
//       },
//       props.undoable
//     );
//     // console.log({ propunex: props.expandedid });
//     const doUnex = props.expandedid.current.unexpand;
//     props.expandedid.current = {};
//     doUnex && doUnex();
//     //redirect('list', props.basePath, data.id, data);
//     refresh();
//   };

//   const onFailure = (error) => {
//     // console.log({ UPDATE_FAIL: error });
//     notify(
//       'Update Failed!',
//       'info',
//       {
//         smart_count: 1,
//       },
//       props.undoable
//     );
//     //redirect('list', props.basePath, data.id, data);
//     refresh();
//   };

//   //console.log({ EditProps: props });
//   const username = JSON.parse(localStorage.getItem('username') || '""');
//   const userEmail = JSON.parse(localStorage.getItem('user-email') || '""');
//   //const qcEditBy = `${username} <${userEmail}>`;
//   const qcEditBy = `${userEmail}`;
//   const editDate = new Date().toISOString();
//   //console.log({ editDate });
//   return (
//     <Edit
//       //onSuccess={onSuccess}
//       onSuccess={onSuccess}
//       onFailure={onFailure}
//       {...props}
//       undoable={false}
//       redirect="list"
//       /* disable the app title change when shown */
//       title=" "
//     >
//       <SimpleForm toolbar={<CustomToolbar />}>
//         <div style={{ width: '100%', height: '100%', display: 'table' }}>
//           <div
//             style={{
//               width: '100%',
//               height: '100%',
//               display: 'table-row',
//             }}
//           >
//             <div
//               style={{
//                 width: '10%',
//                 paddingRight: '2ch',
//                 height: '100%',
//                 display: 'table-cell',
//               }}
//             >
//               <div
//                 style={{
//                   width: '100%',
//                   height: '100%',
//                   display: 'table-row',
//                 }}
//               >
//                 <div
//                   style={{
//                     height: '100%',
//                     width: '50%',
//                     paddingRight: '2ch',
//                     display: 'table-cell',
//                   }}
//                 >
//                   <SelectInput
//                     label="QC Status"
//                     source="QcStatus"
//                     style={{ width: '200px' }}
//                     choices={[
//                       { id: '-2', name: 'Unproducible' },
//                       { id: '-1', name: 'Pass - Resent' },
//                       { id: '0', name: 'Pass' },
//                       { id: '1', name: 'Fail - no audio' },
//                       { id: '2', name: 'Fail - spectator talking' },
//                       { id: '3', name: 'Fail - audio sync' },
//                       { id: '4', name: 'Fail - audio tech issue' },
//                       { id: '5', name: 'Fail - other' },
//                       { id: '6', name: 'Fail - Editorial' },
//                       { id: '8', name: 'Fail - Hawkeye' },
//                       //{ id: '7', name: 'Fail - Takedown' },
//                       //{ id: '9', name: 'Takedown-Complete' },
//                     ]}
//                   />
//                 </div>
//                 <div
//                   style={{
//                     height: '100%',
//                     width: '50%',
//                     display: 'table-cell',
//                   }}
//                 >
//                   <SelectInput
//                     label="Takedown Status"
//                     source="takedownStatus"
//                     style={{ width: '200px' }}
//                     choices={[
//                       { id: '-1', name: 'No Takedown' },
//                       { id: '0', name: 'Takedown' },
//                       { id: '1', name: 'Takedown Complete' },
//                     ]}
//                   />
//                 </div>
//               </div>
//               <div>
//                 <TextInput
//                   disabled
//                   label="Edit By"
//                   source="editQcBy"
//                   initialValue={qcEditBy}
//                 />
//               </div>
//               <div>
//                 <TextInput
//                   disabled
//                   label="Edit Time"
//                   source="editAt"
//                   initialValue={editDate}
//                 />
//               </div>
//             </div>
//             <div>
//               <NotesInput
//                 style={{
//                   width: '45%',
//                   height: '100%',
//                 }}
//                 multiline
//                 id="Notes"
//                 label="QC Note"
//                 resettable={false}
//                 source="Notes"
//               />
//               <div
//                 style={{
//                   width: '5%',
//                   height: '100%',
//                   display: 'inline-block',
//                 }}
//               />
//               <NotesInput
//                 style={{
//                   width: '45%',
//                   height: '100%',
//                 }}
//                 multiline
//                 id="HawkeyeNotes"
//                 source="HawkeyeNotes"
//                 label="Hawkeye Note"
//                 resettable={false}
//               />
//             </div>
//           </div>
//         </div>
//       </SimpleForm>
//     </Edit>
//   );
// };

const IBMSearchFilter = (props) => {
  const filterSet = (
    <Filter {...props}>
      <TextInput label="Search" source="_fullRowQuery" alwaysOn />
      <TextInput
        label="Record ID Search"
        source="_Record_ID_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Round Search"
        source="_Round_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Hole Search"
        source="_Hole_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Stroke Search"
        source="_Stroke_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="TapIn Search"
        source="_TapIn_Search_Term"
        defaultValue=""
      />
      {/* <TextInput
        label="Player ID Search"
        source="_Player_ID_Search_Term"
        defaultValue=""
      /> */}
      <TextInput
        label="Player Name Search"
        source="_Player_Name_Search_Term"
        defaultValue=""
      />
      {/* <TextInput
        label="File Name Search"
        source="_File_Name_Search_Term"
        defaultValue=""
      /> */}
      <TextInput
        label="Shot Date Time Search"
        source="_Shot_Date_Time_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="QC Status Search"
        source="_QC_Status_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Takedown Status Search"
        source="_TKDN_Status_Search_Term"
        defaultValue=""
      />
      {/* <TextInput
        label="QC By Search"
        source="_QC_By_Search_Term"
        defaultValue=""
      /> */}
      {/* <TextInput
        label="Note Search"
        source="_Notes_Search_Term"
        defaultValue=""
      /> */}
      <TextInput
        label="IBM Posted time"
        source="_IbmPostedTime_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Shot Time to IBM on Web"
        source="_TimeToIbmPost_Link_Term"
        defaultValue=""
      />
      <TextInput
        label="IBM-TotalTime Search"
        source="_IBM_TotalTime_Term"
        defaultValue=""
      />
      <TextInput
        label="IBM-Processed Search"
        source="_IbmProcessed_Term"
        defaultValue=""
      />
      <TextInput
        label="IBM-Validated Search"
        source="_IbmValidated_Term"
        defaultValue=""
      />
      <TextInput
        label="Masters Link Search"
        source="_IBM_Link_Term"
        defaultValue=""
      />
    </Filter>
  );
  return filterSet;
};

export const IBMShotList = (props_) => {
  const refresh = useRefresh();
  const savedDoRefresh = useRef(true);
  const expandedid = useRef({});
  const props = { expandedid, ...props_ };
  useRecursiveTimeout(
    () => savedDoRefresh.current && refresh(),
    getRefreshMillis
  );
  const pageBanner = JSON.parse(
    sessionStorage.getItem('__active_screen') || '""'
  );
  const doExpand = (props) => {
    return (
      <ShotEdit
        onMount={() => {
          savedDoRefresh.current = false;
        }}
        onUnmount={() => {
          savedDoRefresh.current = true;
        }}
        {...props}
      />
    );
  };
  const ibmShotList = (
    <React.Fragment>
      <Typography
        style={{ position: 'absolute', transform: 'translateY(1em)' }}
        variant="h6"
        id={pageBanner}
      >
        {pageBanner}
      </Typography>
      <List
        {...props}
        actions={<MyListActions />}
        filter={{ _shotIdx_: `shots_8` }}
        filters={<IBMSearchFilter />}
        bulkActionButtons={false}
        perPage={defaultPerPage}
        pagination={<ESEHPagination />}
        sort={{ field: 'updatedAt', order: 'DESC' }}
        title=" "
      >
        <MyDatagrid
          rowClick="expand"
          expand={doExpand(props)}
          expandedid={expandedid}
          style={{ tableLayout: 'fixed' /*width: '150ch'*/ }}
        >
          <TextField width="8ch" label="Record ID" source="RecordID" />
          <NumberField width="3ch" label="Round" source="Round" />
          <NumberField width="3ch" label="Hole" source="Hole" />
          <NumberField width="3ch" label="Stroke" source="Stroke" />
          <NumberField width="3ch" label="Tap-in" source="TapIn" />
          {/* <TextField width="40px" label="Player ID" source="Player" /> */}
          <TextField width="10ch" label="Player Name" source="Player Name" />
          {/* <TextField width="95px" label="File Name" source="File Name" /> */}
          <TextField width="15ch" label="Shot" source="Shot Date Time" />
          <TextField
            width="15ch"
            label="Hawk-eye"
            source="HawkeyeWriteFile"
          />
          <TextField
            width="15ch"
            label="Exported"
            source="XSquare Found File"
          />
          <TextField width="15ch" label="Pushed" source="XSquare Move File" />
          <TextField width="15ch" label="IBM-Validated" source="IbmValidated" />
          <TextField width="15ch" label="IBM-Processed" source="IbmProcessed" />
          <TextField width="15ch" label="IBM-Posted" source="IBM Posted" />
          <TextField
            width="10ch"
            label="IBM-TotalTime"
            source="IBM-TotalTime"
          />
          <TextField
            width="10ch"
            // label="SMT to IBM-Published"
            label="Shot to Publish"
            source="Shot time to IBM on web"
          />
          <UrlFieldShort
            width="10ch"
            label="Masters.com"
            source="IBM Website Link"
          />
          <TextField width="15ch" label="QC Status" source="QcStatusString" />
          {/* <TextField width="200px" label="QC By" source="QcBy" /> */}
          {/* <TextField width="100px" label="Note" source="Notes" /> */}
        </MyDatagrid>
      </List>
    </React.Fragment>
  );
  return ibmShotList;
};

import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  NumberField,
  DatagridBody,
  TextInput,
  Filter,
  useRefresh,
  SelectInput,
  Edit,
  useQuery, 
  Loading, 
  Error,
  SimpleForm,
  Toolbar,
  SaveButton,
  useNotify,
  useRedirect,
  NumberInput, 
  BooleanInput,
  useShowController,
} from 'react-admin';
import { useRef } from 'react';
import NotesInput from './NotesInput';
import Typography from '@material-ui/core/Typography';
import {
  ESEHPagination,
  defaultPerPage,
  useRecursiveTimeout,
  getRefreshMillis,
} from './ESEHParts';
import { MyListActions } from './MyListActions';
import MyDatagridRow from './MyDataGridRow';
import { withStyles } from '@material-ui/core';
import { Button, Confirm, useUpdateMany, useUnselectAll } from 'react-admin';
import { VisibilityOff } from '@material-ui/icons';
import { StatusPicker } from './components/dropdowns/ra/StatusPicker'
import { HolePicker } from './components/dropdowns/ra/HolePicker'

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const MyDatagridBody = (props) => (
  <DatagridBody
    {...props}
    row={<MyDatagridRow expandedid={props.expandedid} />}
  />
);

const MyDatagrid = (props) => (
  <Datagrid
    {...props}
    body={<MyDatagridBody expandedid={props.expandedid} />}
  />
);

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton label="SAVE" redirect="list" />
  </Toolbar>
));

const IssuesEdit = (props_) => {
  const { onMount, onUnmount, ...props } = props_;
  //navigator.clipboard.writeText(props.id);
  // const {
  //   basePath, // deduced from the location, useful for action buttons
  //   defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
  //   loaded, // boolean that is false until the record is available
  //   loading, // boolean that is true on mount, and false once the record was fetched
  //   record, // record fetched via dataProvider.getOne() based on the id from the location
  //   resource, // the resource name, deduced from the location. e.g. 'posts'
  //   version, // integer used by the refresh feature
  // } 
  // const shit = useShowController(props_);
  // console.log(shit)
  //console.log(props_)
  const refresh = useRefresh();
  React.useEffect(() => {
    //Nothing on mount.
    //console.log({ MOUNTING: { props } });
    onMount && onMount();
    return () => {
      //console.log('UNMOUNTING');
      onUnmount && onUnmount();
    };
  }, [props, onMount, onUnmount]);
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    //console.log({ UPDATE_SUCCESS: data });
    notify(
      'Update Successful!',
      'info',
      {
        smart_count: 1,
      },
      props.undoable
    );
    console.log({ propunex: props.expandedid });
    const doUnex = props.expandedid.current.unexpand;
    props.expandedid.current = {};
    doUnex && doUnex();
    //redirect('list', props.basePath, data.id, data);
    refresh();
  };

  const onFailure = (error) => {
    //console.log({ UPDATE_FAIL: error });
    notify(
      'Update Failed!',
      'info',
      {
        smart_count: 1,
      },
      props.undoable
    );
    //redirect('list', props.basePath, data.id, data);
    refresh();
  };
  const userEmail = JSON.parse(localStorage.getItem('user-email') || '""');
  const issueEditBy = `${userEmail}`;
  console.log('===========')
  console.log(props)
  console.log('===========')
  return (
    <Edit
      //onSuccess={onSuccess}
      onSuccess={onSuccess}
      onFailure={onFailure}
      {...props}
      undoable={false}
      redirect="list"
      /* disable the app title change when shown */
      title=" "
    >
      <SimpleForm toolbar={<CustomToolbar />}>
        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div style={{flex: 1, marginLeft: '10px', marginRight: '10px'}}>
            <SelectInput
              label="Round"
              source="Round"
              inactive="true"
              choices={[
                { id: '1', name: '1' },
                { id: '2', name: '2' },
                { id: '3', name: '3' },
                { id: '4', name: '4' },
              ]}
            />
          </div>
          <HolePicker style={{flex: 1, marginLeft: '10px', marginRight: '10px'}}/>
          <NumberInput style={{flex: 1, marginLeft: '10px', marginRight: '10px'}}  id="issue" source="Stroke" label="Stroke" />
          <StatusPicker 
            style={{flex: 2, marginLeft: '10px', marginRight: '10px'}}
            disabled
            statusType="3"
            id="issue"
            label="Issue Type"
            source="IssueType"
          />
          <StatusPicker 
            style={{flex: 2, marginLeft: '10px', marginRight: '10px'}}
            statusType="4"
            id="issue"
            label="Resolution"
            source="Resolution"
          />
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <NotesInput
            style={{flex: 1, marginLeft: '10px', marginRight: '10px'}}
            multiline
            disabled
            id="issue"
            label="Issue Description"
            resettable={false}
            source="Issue"
          />
          <NotesInput
            style={{flex: 1, marginLeft: '10px', marginRight: '10px'}}
            multiline
            id="issue"
            label="Resolution Message"
            resettable={false}
            source="ResolveMessage"
          />
        </div>
        <TextInput
          disabled
          label="Edited By"
          source="lastEdit"
          initialValue={userEmail}
        />
      </SimpleForm>
    </Edit>
  );
};

const IssuesSearchFilter = (props) => {
  const filterSet = (
    <Filter {...props}>
      <TextInput label="Search" source="IssuesListSearch" alwaysOn />
      <TextInput
        label="Record ID Search"
        source="RecordID"
        defaultValue=""
      />
      <TextInput
        label="Round Search"
        source="Round"
        defaultValue=""
      />
      <TextInput
        label="Hole Search"
        source="Hole"
        defaultValue=""
      />
      <TextInput
        label="Stroke Search"
        source="Stroke"
        defaultValue=""
      />
      <TextInput
        label="Player Name Search"
        source="PlayerName"
        defaultValue=""
      />
      <TextInput
        label="QC Status Search"
        source="QcStatusName"
        defaultValue=""
      />
      <TextInput
        label="Issue Type Search"
        source="IssueTypeName"
        defaultValue=""
      />
      <TextInput
        label="Issue Description Search"
        source="Issue"
        defaultValue=""
      />
      <TextInput
        label="Created By Search"
        source="CreatedBy"
        defaultValue=""
      />
      <TextInput
        label="Resolved By Search"
        source="ResolvedBy"
        defaultValue=""
      />
      <TextInput
        label="Resolve Name Search"
        source="ResolveName"
        defaultValue=""
      />
    </Filter>
  );
  return filterSet;
};

export const IssueList = (props_) => {
  const refresh = useRefresh();
  const savedDoRefresh = useRef(true);
  const expandedid = useRef({});
  const props = { expandedid, ...props_ };
  useRecursiveTimeout(
    () => savedDoRefresh.current && refresh(),
    getRefreshMillis
  );
  const pageBanner = JSON.parse(
    sessionStorage.getItem('__active_screen') || '""'
  );
  const doExpand = (props) => {
    return (
      <IssuesEdit
        onMount={() => {
          savedDoRefresh.current = false;
        }}
        onUnmount={() => {
          savedDoRefresh.current = true;
        }}
        {...props}
      />
    );
  };
  const filterObj = {}
  const fromLoc = sessionStorage.getItem('_issueIndex_')
  filterObj._issueIndex_ = JSON.parse(fromLoc)
  const issueList = (
    <React.Fragment>
      <Typography
        style={{ position: 'absolute', transform: 'translateY(1em)' }}
        variant="h6"
        id={pageBanner}
      >
        {pageBanner}
      </Typography>
      <List
        {...props}
        title=" "
        actions={<MyListActions />}
        filter={filterObj}
        filters={<IssuesSearchFilter />}
        bulkActionButtons={false}
        perPage={defaultPerPage}
        pagination={<ESEHPagination />}
        sort={{ field: 'updatedAt', order: 'DESC' }}
      >
        <MyDatagrid
          rowClick="expand"
          expand={doExpand(props)}
          expandedid={expandedid}
        >
          <TextField label="Record ID" source="RecordID" />
          <NumberField label="Round" source="Round" />
          <NumberField label="Hole" source="Hole" />
          <NumberField label="Stroke" source="Stroke" />
          <TextField label="Player Name" source="PlayerName" />
          <TextField label="QC Status" source="QcStatusName" />
          <TextField label="Issue Type" source="IssueTypeName" />
          <TextField label="Issue Description" source="Issue" />
          <FunctionField label="Created By" render={record => {
            const nameSplit = record.CreatedBy.split('|')
            return nameSplit.length === 3? nameSplit[2] : record.CreatedBy
          }} />
          <TextField label="Closed By" source="ResolvedBy" />
          <TextField label="Resolved" source="ResolveName" />
        </MyDatagrid>
      </List>
    </React.Fragment>
  );
  return issueList;
};

//https://github.com/alexicum/merge-admin/blob/master/src/Auth/index.js
// in src/authProvider.js
import decodeJwt from 'jwt-decode';
import { Auth0Client } from '@auth0/auth0-spa-js';
import auth0 from 'auth0-js';

export default {
  login: ({ username, password }) => {
    const auth = new auth0.WebAuth({
      domain: process.env.REACT_APP_DOMAIN,
      clientID: process.env.REACT_APP_CLIENTID,
      audience: process.env.REACT_APP_AUDIENCE,
      redirectUri: window.location.origin,
      //scope: 'openid profile email',
      scope: 'read:current_user update:current_user_metadata',
    });
    //console.log({ auth });
    const loginVar = new Promise((resolve, reject) => {
      return auth.client.login(
        {
          realm: 'Username-Password-Authentication',
          username,
          password,
          scope: 'openid profile email',
        },
        (err, authResult) => {
          if (err) {
            //setLoginError(false) //to flash it
            //setLoginError(true)
            console.log({ err });
            return reject(err);
          }
          auth.client.userInfo(
            authResult.accessToken,
            (err, userInfoResult) => {
              if (err) {
                return reject(err);
              }
              //console.log({ authResult, userInfoResult });
              const {
                accessToken,
                idToken,
                expiresIn,
                scope,
                tokenType,
              } = authResult;
              const expiresAt = new Date(Date.now() + 1000 * expiresIn);
              const { name, nickname, picture, sub, email } = {
                ...userInfoResult,
              };
              //sessionStorage.setItem('accessToken', authResult.accessToken);
              //sessionStorage.setItem('idToken', authResult.idToken);
              //sessionStorage.setItem('expiresAt', expiresAt);
              //sessionStorage.setItem('nickname', result.nickname);
              //sessionStorage.setItem('picture', result.picture);
              //sessionStorage.setItem('version', '1.0.0');
              localStorage.clear();
              sessionStorage.clear();
              localStorage.setItem('username', JSON.stringify(name));
              localStorage.setItem('user-email', JSON.stringify(email));
              localStorage.setItem('user-pic-url', JSON.stringify(picture));
              localStorage.setItem(
                'user-roles',
                JSON.stringify(
                  userInfoResult['https://eseh.auth.thumbtrack.com/roles']
                )
              );
              localStorage.setItem('expiresAt', JSON.stringify(expiresAt));
              // ***** react-admin
              localStorage.setItem('token', accessToken);
              //localStorage.setItem('roles', JSON.stringify(result["https://viewer-api.thumbwar.tv/roles"]))
              return resolve();
            }
          );
        }
      );
    });
    return loginVar;
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    localStorage.clear();
    sessionStorage.clear();
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    console.log({ ERROR: error, status });
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem('username') &&
      localStorage.getItem('user-email') &&
      localStorage.getItem('token') &&
      localStorage.getItem('expiresAt') &&
      new Date(JSON.parse(localStorage.getItem('expiresAt'))) > Date.now()
      ? Promise.resolve()
      : Promise.reject();
  },
  getPermissions: () => {
    const role = JSON.parse(localStorage.getItem('user-roles') || '""');
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

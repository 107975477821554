import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import copy from 'copy-to-clipboard';

function CopyRecordID(props) {
    let {record} = props;
    const [wasCopied, setWasCopied] = useState(false)

    let handleClick = function(evt) {
        copy(record.id);
        setWasCopied(true);
        setTimeout(() => setWasCopied(false), 3000);
    }

    return (
        <div style={props.style || {}}>
            <Button onClick={handleClick}>
                Copy Record ID
            </Button>
            {wasCopied && <span style={{color: 'red'}}>Copied a new filename to clipboard!</span>}
        </div>
    );
}

export default CopyRecordID
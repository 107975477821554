import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles, createStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import compose from 'recompose/compose';
import { toggleSidebar as toggleSidebarAction } from 'ra-core';
import ThumbWar from './resources/tw_wob.png';
import Avatar from '@material-ui/core/Avatar';
import { UserMenu, LoadingIndicator } from 'react-admin';
import RefreshSelect from './RefreshSelect';
import ExpandMoreOutlined from '@material-ui/icons/ExpandMoreOutlined';
import UnfoldMoreOutlined from '@material-ui/icons/UnfoldMoreOutlined';
import Tooltip from '@material-ui/core/Tooltip';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default,
      position: 'relative',
    },
    appFrame: {
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
      marginTop: '0px',
    },
    contentWithSidebar: {
      display: 'flex',
      flexGrow: 1,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 2,
      padding: theme.spacing(3),
      marginTop: '4em',
      paddingLeft: 5,
    },
    toolbar: {
      paddingRight: 24,
    },
    menuButton: {
      marginLeft: '0.25em',
      marginRight: '0.5em',
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      //transform: 'rotate(180deg)',
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    logo: {
      display: 'block',
      //marginLeft: 'auto',
      marginRight: 'auto',
      maxHeight: '1.75em',
      marginLeft: '100%',
      //width: '50%',
    },
    banner: {
      maxWidth: '400px',
      marginLeft: '30em',
    },
  });
const myCustomIconStyle = {
  avatar: {
    height: 30,
    width: 30,
  },
};

const MyCustomIcon = withStyles(myCustomIconStyle)(({ classes }) => (
  <Avatar
    className={classes.avatar}
    src={
      String(JSON.parse(localStorage.getItem('user-pic-url'))) ||
      String(AccountCircle)
    }
  />
));

//<MyCustomIcon />
const MyUserMenu = (props) => <UserMenu {...props} icon={<MyCustomIcon />} />;

const ZAppBar = ({
  children,
  classes,
  className,
  logo,
  logout,
  open,
  title,
  toggleSidebar,
  userMenu,
  width,
  ...rest
}) => {
  const screen = JSON.parse(sessionStorage.getItem('__active_screen') || '""');
  return (
    <AppBar
      className={className}
      title=""
      color="secondary"
      position="fixed"
      {...rest}
    >
      <Toolbar
        disableGutters
        variant={width === 'xs' ? 'regular' : 'dense'}
        className={classes.toolbar}
        title=""
      >
        <Tooltip title={open ? 'Contract Menu' : 'Expand Menu'}>
          <IconButton
            //title={open ? 'Contract Menu' : 'Expand Menu'}
            aria-disabled={false}
            color="inherit"
            aria-label="open drawer"
            onClick={toggleSidebar}
            className={classNames(classes.menuButton)}
          >
            {open ? <ExpandMoreOutlined /> : <UnfoldMoreOutlined />}
          </IconButton>
        </Tooltip>
        <div style={{ flex: '1' }} />
        <Tooltip title="Thumbwar">
          <div
            style={{
              position: 'absolute',
              left: '50%',
            }}
          >
            <a
              className={classes.logo}
              title=""
              aria-label="Thumbwar"
              href="https://www.thumbwar.com/"
            >
              <img src={ThumbWar} className={classes.logo} alt="Thumbwar" />
            </a>
          </div>
        </Tooltip>
        {Children.count(children) === 0 ? (
          <Typography
            color="inherit"
            className={classes.title}
            id="react-admin-title"
          />
        ) : (
          children
        )}
        {!['dashboard', 'visualizations'].includes(
          String(screen).toLowerCase()
        ) && <LoadingIndicator />}
        <RefreshSelect />
        {cloneElement(userMenu, { logout })}
      </Toolbar>
    </AppBar>
  );
};

ZAppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  logout: PropTypes.element,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  userMenu: PropTypes.node,
  width: PropTypes.string,
  //style: { margin: 0 },
};

ZAppBar.defaultProps = {
  userMenu: <MyUserMenu />,
};

const enhance = compose(
  connect(
    (state) => ({
      //locale: state.i18n.locale, // force redraw on locale change
    }),
    {
      toggleSidebar: toggleSidebarAction,
    }
  ),
  withStyles(styles)
  // withWidth()
);

export default enhance(ZAppBar);

// https://marmelab.com/blog/2019/02/07/react-admin-advanced-recipes-creating-and-editing-a-record-from-the-list-page.html
import React, { useRef, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  SelectInput,
  TextInput,
  Filter,
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  useNotify,
  useRefresh,
  FunctionField,
  useRedirect,
  DatagridBody,
  CardActions, 
  ShowButton,
  ReferenceManyField,
  DateField,
  EditButton
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { 
  withStyles, 
  Button, 
  Accordion, 
  Card, 
  CardHeader, 
  CardContent,
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';

import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddIcon from '@material-ui/icons/Add';



import { MyListActions } from './MyListActions';
import MyDatagridRow from './MyDataGridRow';
import NotesInput from './NotesInput';
import CopyFilename from './components/CopyFilename';


import { rowConditionalStyle } from './layout/commonStyles';
import { IconikCollectionExport } from './IconikCollectionExport'
import {
  ESEHPagination,
  UrlFieldShort,
  defaultPerPage,
  useRecursiveTimeout,
  userQcQuickMap,
  tkdnMap,
  getRefreshMillis,
} from './ESEHParts';

import { IconikProxy } from './components/IconikProxy'
import { NotesReadOnly } from './components/NotesReadOnly'

import CreateNewFolder from '@material-ui/icons/CreateNewFolder';
import { ListDataGetter } from './components/ListDataGetter';

const SearchFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="_fullRowQuery" alwaysOn />
      <TextInput
        label="Record ID Search"
        source="RecordID"
        defaultValue=""
      />
      <TextInput
        label="Player ID Search"
        source="PlayerID"
        defaultValue=""
      />
      <TextInput
        label="Player Name Search"
        source="Player Name"
        defaultValue=""
      />
      <TextInput
        label="Round Search"
        source="Round"
        defaultValue=""
      />
      <TextInput
          label="Hole Search"
          source="Hole"
          defaultValue=""
      />
      <TextInput
        label="Stroke Search"
        source="Stroke"
        defaultValue=""
      />
      <TextInput
        label="Total Strokes Search"
        source="TotalStrokes"
        defaultValue=""
      />
      <TextInput
        label="Par Search"
        source="Par"
        defaultValue=""
      />
      <TextInput
        label="Under Par Search"
        source="UnderPar"
        defaultValue=""
      />
      <TextInput
        label="Over Par Search"
        source="OverPar"
        defaultValue=""
      />
      <SelectInput
        label="In Hole search"
        source="InTheHole"
        defaultValue=""
        choices={[
          { id: 'false', name: 'No'},
          { id: 'true', name: 'Yes'},
        ]}
      />
      <SelectInput
        label="From search"
        source="From"
        defaultValue=""
        choices={[
          { id: 'Fairway Bunker', name: 'Fairway Bunker'},
          { id: 'Fairway', name: 'Fairway'},
          { id: 'First Cut Rough', name: 'First Cut Rough'},
          { id: 'Fringe', name: 'Fringe'},
          { id: 'Greenside Bunker', name: 'Greenside Bunker'},
          { id: 'Green', name: 'Green'},
          { id: 'Intermediate Rough', name: 'Intermediate Rough'},
          { id: 'Native Area', name: 'Native Area'},
          { id: 'Other', name: 'Other'},
          { id: 'Pine Straw', name: 'Pine Straw'},
          { id: 'Primary Rough', name: 'Primary Rough'},
          { id: 'Second Cut Rough', name: 'Second Cut Rough'},
          { id: 'Tee', name: 'Tee'}
        ]}
      />
    </Filter>
  );
};

const MyDatagridBody = (props) => (
  <DatagridBody
    {...props}
    row={<MyDatagridRow expandedid={props.expandedid} />}
  />
);
const MyDatagrid = (props) => (
  <Datagrid
    rowStyle={rowConditionalStyle}
    {...props}
    body={<MyDatagridBody expandedid={props.expandedid} />}
  />
);

export const PlayerShots = (props_) => {
  // console.log('QC Props:', props_);
  const savedDoRefresh = useRef(true);
  const expandedid = useRef({});
  const [ ICEopen, setICEopen ] = useState(false);
  const [ listData, setListData ] = useState(null);
  const [ selectedIds, setSelectedIds ] = useState([]);

  const props = { expandedid, ...props_ };
  const refresh = useRefresh();
  useRecursiveTimeout(
    () => savedDoRefresh.current && refresh(),
    getRefreshMillis
  );
  const pageBanner = JSON.parse(
    sessionStorage.getItem('__active_screen') || '""'
  );
  
  React.useEffect(() => {
    console.log('Props change')
    console.log(props)
  }, [props])
  React.useEffect(() => {
    console.log('Total')
    console.log(listData?.total)
    console.log('FilterValues')
    console.log(listData?.filterValues)
    if (listData?.data) {
      console.log(Object.keys(listData.data).length)
      console.log(listData.data)
    }
    //console.log(listData?.data)
  }, [listData])
  return (
    <React.Fragment key={pageBanner}>
      <Typography
        style={{ position: 'absolute', transform: 'translateY(1em)' }}
        variant="h6"
        id={pageBanner}
      >
        {pageBanner}
      </Typography>
      <List
        {...props}
        filters={
          <SearchFilter />
        }
        bulkActionButtons={
          <Tooltip title="Add selected files to a new or existing iconik collection">
            <Button label="Send to iconik" onClick={() => setICEopen(true)}>
              <CreateNewFolder />
              <span>&nbsp;Send to iconik</span>
            </Button>
          </Tooltip>
        }
        perPage={defaultPerPage}
        actions={
          <MyListActions /> 
        }
        pagination={
          <ESEHPagination />
          // <ESEHPagination 
          //   triggerAllAction={() => setICEopen(true)}
          //   triggerAllToolTipText="Add all files that match current filter to an iconik collection"
          //   triggerAllLabel="ADD ALL TO ICONIK"
          //   triggerAllDisabled={listData?.selectedIds.length > 0 ? true : false}
          // />
        }
        sort={{ field: 'ID', order: 'DESC' }}
        title=" "
      >
      
          <MyDatagrid
            rowClick="expand"
            expand={(
              <PlayerStatsExpanded
                onMount={() => {savedDoRefresh.current = false;}}
                onUnmount={() => {savedDoRefresh.current = true;}}
                {...props}
              />)}
            expandedid={expandedid}
          >
            <TextField label="Player Name" source="Player Name" />
            <NumberField label="Round" source="Round" />
            <NumberField label="Hole" source="Hole" />
            <NumberField label="Stroke" source="Stroke" />
            <TextField label="Record ID" source="RecordID" />
            <TextField label="Player ID" source="PlayerID" />
            <TextField label="From" source="From" />
            <TextField label="Distance To Pin" source="DistanceToPin" />
            <TextField label="Distance Traveled" source="DistanceTraveled" />        
            <TextField label="Strokes" source="TotalStrokes" />        
            <TextField label="Par" source="Par" />        
            <FunctionField
              label="Under Par"
              source="UnderPar"
              sortable={true}
              render={(rec) => (rec && rec.UnderPar < 0 ? 0 : rec.UnderPar)}
            />
            <FunctionField
              label="Over Par"
              source="OverPar"
              sortable={true}
              render={(rec) => (rec && rec.OverPar < 0 ? 0 : rec.OverPar)}
            />
            <FunctionField
              label="In Hole?"
              source="InTheHole"
              sortable={true}
              render={(rec) => (rec && rec.InTheHole ? 'Yes' : 'No')}
            />
            {/* <FunctionField
              label="Hawk-Eye Note"
              source="Hawkeye Notes"
              sortable={true}
              render={(rec) =>
                rec && rec.HawkeyeNotes ? rec.HawkeyeNotes.substr(0, 35) : ''
              }
            /> */}
            <UrlFieldShort label="iconik.io" source="iconikURL" />
            <ListDataGetter 
              setListData={setListData}
            />
          </MyDatagrid>
      </List>
      <IconikCollectionExport 
        onMount={() => {savedDoRefresh.current = false;}}
        onUnmount={() => {savedDoRefresh.current = true;}}
        isOpen={ICEopen}
        setIsOpen={setICEopen}
        listData={listData}
      />
    </React.Fragment>
  );
};

const PlayerStatsExpanded = (props) => {
  const { onMount, onUnmount, basePath } = props
  const refresh = useRefresh();
  const savedDoRefresh = useRef(true);
  const expandedid = useRef({});
  
  React.useEffect(() => {
    //Nothing on mount.
    //console.log({ MOUNTING: { props } });
    onMount && onMount();
    return () => {
      //console.log('UNMOUNTING');
      onUnmount && onUnmount();
    };
  }, [props, onMount, onUnmount]);
  const notify = useNotify();
  
  React.useEffect(() => {
    console.log('Expanded')
    console.log(props)
  }, [])
  

  return (
    <SimpleForm toolbar={<ExpandToolBar />}  {...props}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
        }}
      >
        <div
          style={{
            paddingRight: '2ch',
            flex: 1,
            flexDirection: 'column',
            display: 'flex',
            width: 0,
          }}
        >
          <div flex='1'>
            <NotesReadOnly
              multiline
              disabled={true}
              id="Notes"
              label="QC Note"
              resettable={false}
              source="Notes"
            />
          </div>
          <div flex='1'>
            <NotesReadOnly
              multiline
              disabled={true}
              id="HawkeyeNotes"
              source="HawkeyeNotes"
              label="Hawk-Eye Note"
              resettable={false}
            />
          </div>
        </div>
        <div
          style={{
            width: '50%'
          }}
        >
          <IconikProxy 
            iconikAssetID={props.record.iconikAssetID}
          />
        </div>
      </div>
    </SimpleForm>
  )
}

const ExpandToolBar = (() => (
  <Toolbar>
    <></>
  </Toolbar>
))




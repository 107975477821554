import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DatagridBody,
  TextInput,
  Filter,
  useRefresh,
  SelectInput,
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  useNotify,
  useRedirect,
  BooleanInput,
  BulkActionsToolbar,
} from 'react-admin';
import { useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import {
  ESEHPagination,
  defaultPerPage,
  useRecursiveTimeout,
  getRefreshMillis,
} from './ESEHParts';
import { MyListActions } from './MyListActions';
import MyDatagridRow from './MyDataGridRow';
import { withStyles } from '@material-ui/core';
import { Button, Confirm, useUpdateMany, useUnselectAll } from 'react-admin';
import { VisibilityOff } from '@material-ui/icons';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const MyDatagridBody = (props) => (
  <DatagridBody
    {...props}
    row={<MyDatagridRow expandedid={props.expandedid} />}
  />
);

const MyDatagrid = (props) => (
  <Datagrid
    {...props}
    body={<MyDatagridBody expandedid={props.expandedid} />}
  />
);

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton label="SAVE" redirect="list" />
  </Toolbar>
));

const IgnoreButton = ({ selectedIds }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    'AdminShots',
    selectedIds,
    { views: 0 },
    {
      onSuccess: () => {
        refresh();
        notify('Ignore Toggled');
        unselectAll('AdminShots');
      },
      onFailure: (error) => notify('Error: not toggled', 'warning'),
    }
  );

  return (
    <Button
      label="Toggle-Ignore"
      // style={{ backgroundColor: 'red' }}
      disabled={loading}
      onClick={updateMany}
    >
      <VisibilityOff />
    </Button>
  );
};

const IgnoreBulkActionButtons = (props) => (
  <React.Fragment>
    <IgnoreButton label="Ignore" {...props} />
  </React.Fragment>
);

const AdminEdit = (props_) => {
  const { onMount, onUnmount, ...props } = props_;
  navigator.clipboard.writeText(props.id);
  const refresh = useRefresh();
  React.useEffect(() => {
    //Nothing on mount.
    //console.log({ MOUNTING: { props } });
    onMount && onMount();
    return () => {
      //console.log('UNMOUNTING');
      onUnmount && onUnmount();
    };
  }, [props, onMount, onUnmount]);
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    //console.log({ UPDATE_SUCCESS: data });
    notify(
      'Update Successful!',
      'info',
      {
        smart_count: 1,
      },
      props.undoable
    );
    //console.log({ propunex: props.expandedid });
    const doUnex = props.expandedid.current.unexpand;
    props.expandedid.current = {};
    doUnex && doUnex();
    //redirect('list', props.basePath, data.id, data);
    refresh();
  };

  const onFailure = (error) => {
    //console.log({ UPDATE_FAIL: error });
    notify(
      'Update Failed!',
      'info',
      {
        smart_count: 1,
      },
      props.undoable
    );
    //redirect('list', props.basePath, data.id, data);
    refresh();
  };

  return (
    <Edit
      //onSuccess={onSuccess}
      onSuccess={onSuccess}
      onFailure={onFailure}
      {...props}
      undoable={false}
      redirect="list"
      /* disable the app title change when shown */
      title=" "
    >
      <SimpleForm toolbar={<CustomToolbar />}>
        <SelectInput
          label="Ignore Shot?"
          source="ignored"
          choices={[
            { id: '0', name: 'No' },
            { id: '1', name: 'Yes' },
          ]}
        />
        {/* <BooleanInput label="Ignore Shot" source="ignored" /> */}
      </SimpleForm>
    </Edit>
  );
};

const AdminSearchFilter = (props) => {
  const filterSet = (
    <Filter {...props}>
      <TextInput label="Search" source="_fullRowQuery" alwaysOn />
      <TextInput
        label="Record ID Search"
        source="_Record_ID_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Round Search"
        source="_Round_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Hole Search"
        source="_Hole_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Stroke Search"
        source="_Stroke_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="TapIn Search"
        source="_TapIn_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Xsq Total Process Time"
        source="_XsqTotalProcessTime"
        defaultValue=""
      />
      <TextInput
        label="Player ID Search"
        source="_Player_ID_Search_Term"
        defaultValue=""
      />
      <TextInput
        label="Player Name Search"
        source="_Player_Name_Search_Term"
        defaultValue=""
      />
      {/* <TextInput
        label="File Name Search"
        source="_File_Name_Search_Term"
        defaultValue=""
      /> */}
      <TextInput
        label="Shot Time to IBM on Web"
        source="_TimeToIbmPost_Link_Term"
        defaultValue=""
      />
      <TextInput
        label="Shot Date Time Search"
        source="_Shot_Date_Time_Search_Term"
        defaultValue=""
      />
      <SelectInput
        label="Shot Ignored"
        source="_Shot_Ignored_Search_Term"
        choices={[
          { id: '0', name: 'No' },
          { id: '1', name: 'Yes' },
        ]}
      />
    </Filter>
  );
  return filterSet;
};

export const AdminShotList = (props_) => {
  const refresh = useRefresh();
  const savedDoRefresh = useRef(true);
  const expandedid = useRef({});
  const props = { expandedid, ...props_ };
  useRecursiveTimeout(
    () => savedDoRefresh.current && refresh(),
    getRefreshMillis
  );
  const pageBanner = JSON.parse(
    sessionStorage.getItem('__active_screen') || '""'
  );
  const doExpand = (props) => {
    return (
      <AdminEdit
        onMount={() => {
          savedDoRefresh.current = false;
        }}
        onUnmount={() => {
          savedDoRefresh.current = true;
        }}
        {...props}
      />
    );
  };
  const adminShotList = (
    <React.Fragment>
      <Typography
        style={{ position: 'absolute', transform: 'translateY(1em)' }}
        variant="h6"
        id={pageBanner}
      >
        {pageBanner}
      </Typography>
      <List
        {...props}
        title=" "
        actions={<MyListActions />}
        filter={{ _shotIdx_: `shots_0` }}
        filters={<AdminSearchFilter />}
        bulkActionButtons={<IgnoreBulkActionButtons />}
        perPage={defaultPerPage}
        pagination={<ESEHPagination />}
        sort={{ field: 'updatedAt', order: 'DESC' }}
      >
        <MyDatagrid
          rowClick="expand"
          //expand={doExpand(props)}
          expandedid={expandedid}
        >
          <TextField label="Record ID" source="RecordID" />
          <NumberField label="Round" source="Round" />
          <NumberField label="Hole" source="Hole" />
          <NumberField label="Stroke" source="Stroke" />
          <NumberField label="Tap-in" source="TapIn" />
          <TextField
            label="Xsq Total Process Time"
            source="XsqTotalProcessTime"
          />
          <TextField label="Player ID" source="Player" />
          <TextField label="Player Name" source="Player Name" />
          {/* <TextField label="File Name" source="File Name" /> */}
          <TextField label="SMT to Posted" source="Shot time to IBM on web" />
          <TextField label="Shot" source="Shot Date Time" />
          <NumberField label="Ignore" source="ignoredString" />
        </MyDatagrid>
      </List>
    </React.Fragment>
  );
  return adminShotList;
};

import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Container } from 'react-bootstrap';
import Typography from '@material-ui/core/Typography';

export default () => {
  sessionStorage.setItem('__active_screen', JSON.stringify('Visualizations'));
  const username = JSON.parse(localStorage.getItem('username') || '""');
  const roles = JSON.parse(localStorage.getItem('user-roles') || '[]');

  return (
    <Container>
      <Card>
        <CardHeader title="Visualizations (Beta)" variant="h6" />
        {(roles.includes('admin-user') && (
          <CardContent>
            <iframe
              src="https://zgfuig1jy2xlbgxhbibpcybzdxblcibibgluzcbhbmqgaxqncybhbwf6aw5nigh.thumbwar.com/d/LKyJ7jyGk/overview?orgId=1&refresh=30s&theme=dark&kiosk"
              width="100%"
              height="1000"
              frameBorder="0"
              title="Admin Visualizations"
            ></iframe>
          </CardContent>
        )) ||
          (roles.includes('3rdparty-user') && (
            <CardContent>
              <iframe
                src="https://zgfuig1jy2xlbgxhbibpcybzdxblcibibgluzcbhbmqgaxqncybhbwf6aw5nigh.thumbwar.com/d/LKyJ7jyGk/overview?orgId=1&refresh=30s&theme=dark&kiosk"
                width="100%"
                height="1000"
                frameBorder="0"
                title="Visualizations"
              ></iframe>
            </CardContent>
          ))}
      </Card>
    </Container>
  );
};

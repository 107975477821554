import * as React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useLogin, useNotify, useSafeSetState } from 'ra-core';
import { motion } from 'framer-motion';

import loginIcon from './resources/login.png';
import './Login.css';

const useStyles = makeStyles(
  (theme) => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'RaLoginForm' }
);

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    className={
      props.id === 'username'
        ? 'input.Login__username'
        : 'input.Login__password'
    }
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const MyLoginForm = (props) => {
  const { redirectTo } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const [invalid, setInvalid] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);

  const validate = (values) => {
    const errors = { username: undefined, password: undefined };

    if (!values.username) {
      errors.username = 'REQUIRED'; //translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = 'REQUIRED'; //translate('ra.validation.required');
    }
    return errors;
  };

  const submit = (values) => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        setLoading(false);
        setInvalid(false);
      })
      .catch((error) => {
        setLoading(false);
        setInvalid(true);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          'warning'
        );
      });
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="Login" noValidate>
          <motion.div
            className="Login__container"
            style={{ transform: 'none' }}
            initial={{ y: 5 }}
            transition={{ duration: 0.5, delay: 0.0, ease: 'easeOut' }}
            animate={{ y: 0 }}
          >
            <div className="Login__titlecontainer">
              <div className="Login__title">
                <motion.span
                  initial={{ opacity: 0.0, x: 10 }}
                  transition={{
                    duration: 0.5,
                    delay: 0.0,
                    ease: 'easeOut',
                  }}
                  animate={{ opacity: 1.0, x: 0 }}
                  className="Login__company"
                >
                  THUMBWAR
                </motion.span>
                <motion.span
                  initial={{ opacity: 0.0, x: 10 }}
                  transition={{
                    duration: 0.5,
                    delay: 0.1,
                    ease: 'easeOut',
                  }}
                  animate={{ opacity: 1.0, x: 0 }}
                  className="Login__project"
                >
                  ESEH
                </motion.span>
              </div>
            </div>
            <motion.div
              initial={{ scale: 0.0, opacity: 0.0 }}
              transition={{
                duration: 1.5,
                delay: 0.0,
                ease: [0.07, 0.96, 0.14, 0.96],
              }}
              animate={{ scale: 1.0, opacity: 1.0 }}
              className="Login__vl"
            ></motion.div>
            <div
              className="Login__fields"
              style={{
                //position: 'absolute',
                opacity: 1,
                transform: 'none',
                marginTop: '8em',
                marginLeft: '10em',
                height: '1em',
                width: '1em',
              }}
            >
              {/* <div className={classes.input}> */}
              <div className="Login__username">
                <Field
                  autoFocus
                  id="username"
                  name="username"
                  component={Input}
                  label="USERNAME" //{translate('ra.auth.username')}
                  disabled={loading}
                  className={classes.input}
                  autoComplete="on"
                />
              </div>
              {/* <div className={classes.input}> */}
              <div className="Login__password">
                <Field
                  id="password"
                  name="password"
                  component={Input}
                  label="PASSWORD" //{translate('ra.auth.password')}
                  type="password"
                  disabled={loading}
                  autoComplete="current-password"
                  className={classes.input}
                />
              </div>
              <div className="Login__footer">
                <span className="Login__login">
                  <IconButton
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    className={classes.button}
                  >
                    <img
                      className={classes.icon}
                      src={loginIcon}
                      alt="login"
                      style={{
                        //position: 'absolute',
                        opacity: 1,
                        transform: 'none',
                        // fff
                        //marginRight: '26em',
                        height: '1em',
                        width: '1em',
                      }}
                    />
                  </IconButton>
                </span>
              </div>
            </div>
          </motion.div>
        </form>
      )}
    />
  );
};

MyLoginForm.propTypes = {
  redirectTo: PropTypes.string,
};

export default MyLoginForm;

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { withStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';

import { setRefreshMillis } from './ESEHParts';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
});

class RefreshSelect extends React.Component {
  state = {
    open: false,
    selectName: '5s',
  };

  handleToggle = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  handleCloseNoChange = (event) => {
    if (this.anchorEl.contains(event.target)) {
      // console.log({ REFRESH_EVENT_SET_0: event.target });
      return;
    }
    this.setState({ open: false });
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      // console.log({ REFRESH_EVENT_SET_1: event.target });
      return;
    }
    // console.log({ REFRESH_EVENT_SET_2: event.target, VAL: event.target.value });
    setRefreshMillis(event.target.value);
    this.setState({ open: false, selectName: event.target.textContent });
  };

  render() {
    const { classes } = this.props;
    const { open } = this.state;

    return (
      <div
        className={classes.root}
        //style={{ width: '30px', height: '30px' }}
      >
        <Tooltip title="Refresh Rate">
          <Button
            style={{ textTransform: 'none' }}
            buttonRef={(node) => {
              this.anchorEl = node;
            }}
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-label="Refresh Rate"
            aria-haspopup="true"
            aria-hidden="false"
            //title="Refresh Rate"
            id="refresh-rate"
            onClick={this.handleToggle}
          >
            {/* <UpdateIcon /> */}
            {this.state.selectName}
          </Button>
        </Tooltip>
        <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleCloseNoChange}>
                  <MenuList>
                    <MenuItem onClick={this.handleClose} value="0">
                      ||
                    </MenuItem>
                    <MenuItem onClick={this.handleClose} value="5000">
                      5s
                    </MenuItem>
                    <MenuItem onClick={this.handleClose} value="15000">
                      15s
                    </MenuItem>
                    <MenuItem onClick={this.handleClose} value="30000">
                      30s
                    </MenuItem>
                    <MenuItem onClick={this.handleClose} value="60000">
                      60s
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

RefreshSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RefreshSelect);

// in src/Dashboard.js
import React from 'react';
import { useRef, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Container } from 'react-bootstrap';
import Typography from '@material-ui/core/Typography';

export default () => {
  sessionStorage.setItem('__active_screen', JSON.stringify('Dashboard'));
  const username = JSON.parse(localStorage.getItem('username') || '""');
  return (
    <Container>
      <Card>
        <CardHeader title="Dashboard" variant="h6" />
        <CardContent>Welcome, {username}!</CardContent>
      </Card>
    </Container>
  );
};

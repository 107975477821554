import * as React from 'react';
import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  TablePagination,
  Toolbar,
  useMediaQuery,
  Theme,
} from '@material-ui/core';
import {
  useTranslate,
  useListPaginationContext,
  sanitizeListRestProps,
  ComponentPropType,
} from 'ra-core';

import { PaginationActions, PaginationLimit, Button } from 'react-admin';

import { Tooltip } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const popToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

const emptyArray = [];

const MyPagination = (props) => {
  const { rowsPerPageOptions, actions, limit, ...rest } = props;
  const {
    loading,
    page,
    perPage,
    total,
    setPage,
    setPerPage,
  } = useListPaginationContext(props);
  const translate = useTranslate();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const totalPages = useMemo(() => {
    return Math.ceil(total / perPage) || 1;
  }, [perPage, total]);

  /**
   * Warning: material-ui's page is 0-based
   */
  const handlePageChange = useCallback(
    (event, page) => {
      event && event.stopPropagation();
      if (page < 0 || page > totalPages - 1) {
        throw new Error(
          translate('ra.navigation.page_out_of_boundaries', {
            page: page + 1,
          })
        );
      }
      setPage(page + 1);
      popToTop();
    },
    [totalPages, setPage, translate]
  );

  const handlePerPageChange = useCallback(
    (event) => {
      setPerPage(event.target.value);
    },
    [setPerPage]
  );

  const labelDisplayedRows = useCallback(
    ({ from, to, count }) =>
      translate('ra.navigation.page_range_info', {
        offsetBegin: from,
        offsetEnd: to,
        total: count,
      }),
    [translate]
  );

  // Avoid rendering TablePagination if "page" value is invalid
  if (total === null || total === 0 || page < 1 || page > totalPages) {
    return loading ? <Toolbar variant="dense" /> : limit;
  }

  if (isSmall) {
    return (
      <TablePagination
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onChangePage={handlePageChange}
        rowsPerPageOptions={emptyArray}
        component="span"
        labelDisplayedRows={labelDisplayedRows}
        {...sanitizeListRestProps(rest)}
      />
    );
  }

  return (
    <>
      <TablePagination
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        ActionsComponent={actions}
        component="span"
        labelRowsPerPage={translate('ra.navigation.page_rows_per_page')}
        labelDisplayedRows={labelDisplayedRows}
        rowsPerPageOptions={rowsPerPageOptions}
        {...sanitizeListRestProps(rest)}
      />
      {/* {props.triggerAllAction && 
        <span>
         <Tooltip placement="right" title={props.triggerAllToolTipText}>
           <Button
             onClick={props.triggerAllAction}
             label={props.triggerAllLabel}
             disabled={props.triggerAllDisabled? props.triggerAllDisabled : false}
           >
             <DoneAllIcon />
           </Button>
         </Tooltip>
       </span>
      } */}
    </>
  );
};

MyPagination.propTypes = {
  actions: ComponentPropType,
  limit: PropTypes.element,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

MyPagination.defaultProps = {
  actions: PaginationActions,
  limit: <PaginationLimit />,
  rowsPerPageOptions: [5, 10, 25],
};

export default React.memo(MyPagination);

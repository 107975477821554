import React, { useRef, useState } from 'react';
import { 
  Button, 
  TextInput,
  SaveButton,
  FormWithRedirect,
  useCreate,
  RadioButtonGroupInput,
  useNotify
} from 'react-admin';



import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { SelectIconikCollection } from './components/dropdowns/ra/IconikCollection'
import { Tooltip } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';

export const IconikCollectionExport = (props) => {
  const { onMount, onUnmount, isOpen, setIsOpen, bulkTrigger, allTrigger } = props
  
  const [ records, setRecords ] = useState([]) 
  const [ title, setTitle ] = useState('Add files to an iconik collection')
  const [ syncType, setSyncType ] = useState('createNew')
  const [ triggerType, setTriggerType ] = useState(null)
  const [ assetIDs, setAssetIDs ] = useState([])
  const [create, { loading }] = useCreate('Production/PlayerShots');
  //const { data, selectedIds } = useListContext()
  const notify = useNotify()

  React.useEffect(() => {
    //Nothing on mount.
    // console.log(props.selectedIds)
    // console.log({ MOUNTING: { props } });
    if (props.listData?.selectedIds && props.listData.selectedIds?.length > 0) {
      
      onMount && onMount();
      // console.log('DATA')
      // console.log(data)
      setRecords(props.listData.selectedIds.map((x) => props.listData.data[x]))
    }
    return () => {
      //console.log('UNMOUNTING');
      onUnmount && onUnmount();
    };
  }, [props, onMount, onUnmount]);

  React.useEffect(() => {
    let fileOrFiles = 'files'
    let numOfFiles = null
    
    if (props.listData?.selectedIds.length > 0) {
      numOfFiles = props.listData?.selectedIds.length 
      setTriggerType('bulkAction')
    } else {
      numOfFiles = props.listData?.total
      setTriggerType('allMatching')
    }
    //let numOfFiles = props.listData?.selectedIds.length ? props.listData?.selectedIds.length : props.listData?.total
    if (numOfFiles === 1) {
      fileOrFiles = 'file'
    }
    setTitle(`Add ${numOfFiles ? numOfFiles + ' ': ''}${fileOrFiles} to an iconik collection`)
  }, [props.listData])

  React.useEffect(() => {
    console.log('SelectedIDs', props.listData?.selectedIds.length)
    console.log('Rec Number', records)
    const updatedAssetIDs = records.reduce((arr, x) => {
      console.log('Checking')
      console.log(x.iconikAssetID)
      console.log(!arr.includes(x.iconikAssetID))
      if (x.iconikAssetID && !arr.includes(x.iconikAssetID)) {
        arr.push(x.iconikAssetID)
      }
      return arr
    }, [])
    setAssetIDs(updatedAssetIDs)
    console.log('Total assetIDs')
    console.log(updatedAssetIDs.length)
  }, [records])

  const handleClick = () => {
    setIsOpen(true)
    //setIsOpen(isOpen = !isOpen)
  }

  const handleCancel = () => {
    setIsOpen(false)
  }

  const handleSubmitWithRedirect = (value) => {
    console.log(value)
    let assetIDs = records.reduce((arr, x) => {
      if (x.iconikAssetID) {
        arr.push(x.iconikAssetID)
      }
      return arr
    }, [])
    // if (triggerType === 'bulkAction') {
    //   assetIDs = records.reduce((arr, x) => {
    //     if (x.iconikAssetID) {
    //       arr.push(x.iconikAssetID)
    //     }
    //     return arr
    //   }, [])
    // }
    setIsOpen(false)
   
    create({
      payload: {
        data: {
          assetIDs, 
          syncAction: value
        }
      }
    },
    {
      onSuccess: ({ data }) => {
          
        //setShowDialog(false);
        // Update the comment form to target the newly created post
        // Updating the ReferenceInput value will force it to reload the available posts
        // form.change('post_id', data.id);
        // onChange();
        notify(
          'Success',
          'info',
          {
            autoHideDuration: 5000,
          }
        )
      },
      onFailure: ({ error }) => {
        notify(error.message, 'error');
      }
    })
  }

  const handleCreationTypeChange = (value) => {
    console.log(value)
    if (value === 'selectExisting') {
      setSyncType('selectExisting')
    } else if (value === 'createNew') {
      setSyncType('createNew')
    }
  }

  

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        open={isOpen}
        onClose={() => console.log('Close')}
        aria-label={title}
      >
        <DialogTitle>{title}</DialogTitle>
          <FormWithRedirect
            resource="posts"
            save={handleSubmitWithRedirect}
            onChange={handleCreationTypeChange}
            render={({
                handleSubmitWithRedirect,
                pristine,
                saving
            }) => (
              <>
                <DialogContent>
                  <RadioButtonGroupInput 
                    label="How would you like to add these files?"
                    source="syncType" 
                    defaultValue={syncType}
                    onChange={handleCreationTypeChange}
                    choices={[
                      { id: 'createNew', name: 'Create New Collection' },
                      { id: 'selectExisting', name: 'Select Existing Collection' },
                    ]} 
                  />
                  
                  {
                    syncType === 'selectExisting' ? 
                      <SelectIconikCollection 
                        style={{flex: 2, marginLeft: '10px', marginRight: '10px'}}
                        statusType="4"
                        label="Select an existing collection"
                        source="IconikCollections"
                      />
                    :
                    <TextInput
                      label="Collection Title"  
                      source="collectionTitle"
                      //validate={required()}
                      fullWidth
                    /> 
                  }
                </DialogContent>
                <DialogActions>
                  <Button
                    label="ra.action.cancel"
                    onClick={handleCancel}
                    //disabled={loading}
                  >
                    <IconCancel />
                  </Button>
                  <SaveButton
                    handleSubmitWithRedirect={
                      handleSubmitWithRedirect
                    }
                    // pristine={pristine}
                    // saving={saving}
                    // disabled={loading}
                  />
                </DialogActions>
              </>
            )}
        />
      </Dialog>
    </React.Fragment>
  )
}
import React, { cloneElement } from 'react';
import {
  useListContext,
  TopToolbar,
  Button,
  sanitizeListRestProps,
} from 'react-admin';
import ClearAll from '@material-ui/icons/ClearAll';
import Tooltip from '@material-ui/core/Tooltip';

import { viewToDefaultSort } from './ESEHParts';

export const MyListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <div style={{ display: 'flex' }}>
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <span>
          {filters &&
            cloneElement(filters, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: 'button',
          })}
        </span>
        {filters && (
          <Tooltip title="Clear Filters">
            <span>
              <Button
                onClick={() => {
                  rest.setFilters({}, {});
                }}
                label="Clear Filters"
              >
                <ClearAll />
              </Button>
            </span>
          </Tooltip>
        )}
        <Tooltip title="Clear Sort">
          <span>
            <Button
              onClick={() => {
                //console.log({ title: rest.defaultTitle.toLowerCase() });
                rest.setSort(
                  viewToDefaultSort[rest.defaultTitle.toLowerCase()].field,
                  'DESC'
                  // viewToDefaultSort[rest.defaultTitle.toLowerCase()].order
                );
              }}
              label="Clear Sort"
            >
              <ClearAll />
            </Button>
          </span>
        </Tooltip>

        {/* Add your custom actions */}
      </TopToolbar>
    </div>
  );
};

import * as React from 'react';
import { makeStyles, Chip } from '@material-ui/core';
import { useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import { useTranslate, PaginationActions } from 'react-admin';

import MyPagination from './MyPagination';

//250 25
//export const defaultPerPage = 250;
export const defaultPerPage = process.env.NODE_ENV === 'production' ? 250 : 25;

//Default and min
export const refreshMilliseconds = 5000;

export const setRefreshMillis = (millis_) => {
  let millis = millis_;
  if (millis === undefined) {
    millis = refreshMilliseconds;
  }
  //console.log({ SET_REFRESH: millis });
  sessionStorage.setItem('__refresh_millis', JSON.stringify(millis));
};

export const getRefreshMillis = () => {
  // console.log({
  //   GET_REFRESH: JSON.parse(sessionStorage.getItem('__refresh_millis') || '""'),
  // });
  const newRefreshMillis = Number(
    JSON.parse(
      sessionStorage.getItem('__refresh_millis') || `"${refreshMilliseconds}"`
    )
  );
  if (newRefreshMillis === 0) {
    // console.log({ REFRESH_SET: 0 });
    return 0;
  } else if (
    isNaN(newRefreshMillis) ||
    newRefreshMillis < refreshMilliseconds
  ) {
    // console.log({ REFRESH_SET: refreshMilliseconds });
    return refreshMilliseconds;
  } else {
    // console.log({ REFRESH_SET: newRefreshMillis });
    return newRefreshMillis;
  }
};
// const shotMap = {
//   1: 'Takedowns by round',
//   2: 'TW ESEH Shots with IBM Missing xSquare',
//   3: 'TW ESEH Shots - finish xsquare, not on IBM',
//   4: 'TW ESEH Shots with durations btwn steps',
//   5: 'TW ESEH Shots with timing',
//   6: 'TW  Hawk-Eye Not Clipped',
//   7: 'TW Master',
// };

export const shotMap = {
  1: 'Takedowns',
  2: 'IBM Only',
  3: 'XSquare Only',
  4: 'Durations',
  5: 'Shot Timing',
  6: 'Hawk-Eye Queue',
  7: 'Overview',
};

export const tkdnMap = {
  7: {
    description: 'Takedown Queue',
  },
  9: { description: 'Takedown Complete' },
};
export const userQcQuickMap = {
  1: {
    description: 'Failed Queue',
  },
  6: { description: 'Edit Queue' },
  // 10: {
  //   description: 'Reprocessed',
  // },
  0: { description: 'Queue' },
  8: { description: 'QC Hawk-Eye Queue' },
  11: {
    description: 'Unproducible',
  },
};

export const issueMap = {
  1: 'Issues (Resolved)',
  0: 'Issues (Unresolved)'
}


export const qcNumToStr = {
  '-2': 'Unproducible',
  '-1': 'Pass - Resent',
  0: 'Pass',
  1: 'Fail - no audio',
  2: 'Fail - spectator talking',
  3: 'Fail - audio sync',
  4: 'Fail - audio tech issue',
  5: 'Fail - other',
  6: 'Fail - Editorial',
  7: 'Fail - Takedown',
  8: 'Fail - Hawkeye',
  9: 'Takedown - Complete',
};

export function useRecursiveTimeout(callback, delayFunc) {
  const savedCallback = useRef(callback);
  let delay = delayFunc();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let id;
    function tick() {
      delay = delayFunc();
      // console.log({ TICK: delay });
      if (delay !== 0) {
        const ret = savedCallback.current();
        if (ret instanceof Promise) {
          ret.then(() => {
            if (delay !== null) {
              id = setTimeout(tick, delay);
            }
          });
        } else {
          if (delay !== null) {
            id = setTimeout(tick, delay);
          }
        }
      } else {
        id = setTimeout(tick, refreshMilliseconds);
      }
    }
    if (delay !== null) {
      id = setTimeout(tick, delay);
      return () => id && clearTimeout(id);
    }
  }, [delay]);
}

export const ESEHPaginationActions = (props) => (
  <div>
    <PaginationActions {...props} />
  </div>
);

export const ESEHPagination = (props) => {
  /*
  const classes = makeStyles((theme) => ({
    overrides: {
      color: 'red',
      spacer: {
        flex: 0,
      },
    },
  }));
  console.log({ classes });
  */
  return (
    <MyPagination
      {...props}
      //classes={classes.Pagination}
      rowsPerPageOptions={[defaultPerPage]}
      ActionsComponent={ESEHPaginationActions}
    />
  );
};

const useQuickFilterStyles = makeStyles((theme) => ({
  chip: {
    marginBottom: theme.spacing(1),
  },
}));

const ESEHQuickFilter = ({ key, label }) => {
  //console.log({ key });
  //const description = userQcQuickMap[label].description;
  const translate = useTranslate();
  //const translated = translate(description);
  const classes = useQuickFilterStyles();
  return <Chip className={classes.chip} label={label} value={true} />;
};

//DataGrid expand={<TextField source="Notes" />}
//const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
export const UrlFieldShort = ({ record, source }) => {
  return record && source && record[source] ? (
    <Button
      className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
      target="_blank"
      href={record[source]}
      variant="outlined"
      color="primary"
    >
      LINK
    </Button>
  ) : (
    ''
  );
};

async function getDataTable() {
  const data = await fetch('/qualitycontrol', {
    method: 'get',
  })
    .then((res) => res.text())
    .then((resText) => {
      //console.log('resText:', resText);
      return resText;
    });
  //console.log('data:', data);
  return data;
}

export const cutEmail = (str) => (str ? str.replace(/<.*>/, '') : '');

export const viewToDefaultSort = {
  shots: { field: 'updatedAt', order: 'DESC' },
  adminshots: { field: 'updatedAt', order: 'DESC' },
  qualitycontrols: { field: 'updatedAt', order: 'DESC' },
  roundreports: { field: 'Round', order: 'DESC' },
  holereports: { field: 'Hole', order: 'DESC' },
  ibmshots: { field: 'updatedAt', order: 'DESC' },
};
//module.exports = [ESEHPagination, defaultPerPage, UrlFieldShort];

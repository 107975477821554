import * as React from 'react';
//import QCHoleContext from './QCHoleContext';
//import compose from 'recompose/compose';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources, DashboardMenuItem } from 'react-admin';
import LabelIcon from '@material-ui/icons/Label';
import GolfCourse from '@material-ui/icons/GolfCourse';
import SportsGolf from '@material-ui/icons/SportsGolf';
import Warning from '@material-ui/icons/Warning';
import Adjust from '@material-ui/icons/Adjust';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import Gavel from '@material-ui/icons/Gavel';
import SportsGolfIcon from '@material-ui/icons//SportsGolf';
import WarningIcon from '@material-ui/icons/Warning';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Computer from '@material-ui/icons/Computer';
import QCHead from '@material-ui/icons/AssignmentTurnedIn';
import CheckCircleOutlineRounded from '@material-ui/icons/CheckCircleOutlineRounded';
import CheckCircleRounded from '@material-ui/icons/CheckCircleRounded';
import DashboardIcon from '@material-ui/icons/Dashboard';
//import BaselineFactCheck from '@iconify/icons-ic/baseline-fact-check';

import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import { userQcQuickMap, tkdnMap, shotMap, issueMap } from './ESEHParts';
import qs from 'qs';

const styles = {
  listItem: {
    paddingLeft: '1rem',
  },
  listItemText: {
    paddingLeft: 2,
    fontSize: '1rem',
  },
  sidebarIsOpen: {
    paddingLeft: 25,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
};

const Menu = ({ logout }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const roles = JSON.parse(localStorage.getItem('user-roles') || '[]');

  const screen = JSON.parse(sessionStorage.getItem('__active_screen') || '""');
  const token = localStorage.getItem('token');
  let [highliteSel, setHighliteSel] = React.useState(screen);
  const [openOne, setOpenOne] = React.useState(false);
  const [openTwo, setOpenTwo] = React.useState(false);
  const [openThree, setOpenThree] = React.useState(false);
  const [openFour, setOpenFour] = React.useState(false);

  // Breaking thing.
  const myRoute_ = window.location.hash
    .split('?')[0]
    .substring(2)
    .toLowerCase();
  //console.log({ myRoute_ });
  const myRoute = myRoute_ === '' ? 'dashboard' : myRoute_;
  const myNavRoute = JSON.parse(
    sessionStorage.getItem('_path') || '""'
  ).toLowerCase();

  //console.log({ myRoute, myNavRoute, screen, highliteSel });
  if ((myRoute !== myNavRoute || screen !== highliteSel) && token) {
    // console.log({ ZZZZZ: 1, myRoute, myNavRoute, screen, highliteSel });
    sessionStorage.setItem('_path', JSON.stringify('dashboard'));
    sessionStorage.setItem('__active_screen', JSON.stringify('Dashboard'));
    setHighliteSel('Dashboard');
    sessionStorage.setItem('__sround', JSON.stringify(0));
    sessionStorage.setItem('__qchole', JSON.stringify(0));
    sessionStorage.setItem('_shotIdx_', JSON.stringify(0));
    Object.keys(userQcQuickMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    Object.keys(tkdnMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    if (window && window.location && window.location.pathname) {
      //console.log({ AAAAA: 1, myRoute, myNavRoute, screen, highliteSel });
      window.location.href = '/';
    }
  }
  // Breaking thing.
  const handleClickOne = (sel) => {
    sessionStorage.setItem('_path', JSON.stringify('holereport'));
    // console.log('handleClickOne');
    setHighliteSel(sel);
    sessionStorage.setItem('__active_screen', JSON.stringify(sel));
    setOpenOne(!openOne);
    setOpenTwo(false);
    setOpenThree(false);
    setOpenFour(false);
    sessionStorage.setItem('__sround', JSON.stringify(0));
  };
  const handleClickTwo = (sel) => {
    sessionStorage.setItem('_path', JSON.stringify('qualitycontrol'));
    // console.log('handleClickTwo');
    setHighliteSel(sel);
    sessionStorage.setItem('__active_screen', JSON.stringify(sel));
    sessionStorage.setItem('__qchole', JSON.stringify(0));
    setOpenOne(false);
    setOpenTwo(!openTwo);
    setOpenThree(false);
    setOpenFour(false);
  };
  const handleClickThree = (sel) => {
    sessionStorage.setItem('_shotIdx_', JSON.stringify(1));
    sessionStorage.setItem('_path', JSON.stringify('shots'));
    // console.log('handleClickThree');
    setHighliteSel(sel);
    sessionStorage.setItem('__active_screen', JSON.stringify(sel));
    sessionStorage.setItem('__sround', JSON.stringify(0));
    setOpenThree(!openThree);
    setOpenOne(false);
    setOpenTwo(false);
    setOpenFour(false);
  };
  const handleClickFour = (sel) => {
    sessionStorage.setItem('_path', JSON.stringify('qualitycontrol'));
    // console.log('handleClickFour');
    setHighliteSel(sel);
    sessionStorage.setItem('__active_screen', JSON.stringify(sel));
    sessionStorage.setItem('__sround', JSON.stringify(0));
    Object.keys(userQcQuickMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    Object.keys(tkdnMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    setOpenOne(false);
    setOpenTwo(false);
    setOpenThree(false);
    setOpenFour(!openFour);
  };
  const handleVizClick = (sel) => {
    setHighliteSel(sel);
    sessionStorage.setItem('_path', JSON.stringify('visualizations'));
    sessionStorage.setItem('__active_screen', JSON.stringify(sel));
    // console.log({ saving: { _shotIdx_ } });
    Object.keys(userQcQuickMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    Object.keys(tkdnMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    setOpenOne(false);
    setOpenTwo(false);
    setOpenThree(false);
    setOpenFour(false);
  };
  const handleShotSelect = (sel, _shotIdx_) => {
    sessionStorage.setItem('_path', JSON.stringify('shots'));
    // console.log('handleShotSelect');
    setHighliteSel(sel);
    sessionStorage.setItem('__active_screen', JSON.stringify(sel));
    // console.log({ saving: { _shotIdx_ } });
    Object.keys(userQcQuickMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    Object.keys(tkdnMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    sessionStorage.setItem('_shotIdx_', JSON.stringify(_shotIdx_));
    setOpenOne(false);
    setOpenTwo(false);
    setOpenThree(false);
    setOpenFour(false);
  };
  const handleTakeDownByRound = (sel, _shotIdx_, __sround) => {
    sessionStorage.setItem('_path', JSON.stringify('shots'));
    //console.log('handleTakeDownByRound');
    setHighliteSel(sel);
    sessionStorage.setItem('__active_screen', JSON.stringify(sel));
    //console.log({ saving: { _shotIdx_ } });
    Object.keys(userQcQuickMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    Object.keys(tkdnMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    sessionStorage.setItem('_shotIdx_', JSON.stringify(_shotIdx_));
    sessionStorage.setItem('__sround', JSON.stringify(__sround));
    setOpenOne(false);
    setOpenTwo(false);
    //setOpenThree(false);
    setOpenFour(false);
  };
  const handleRoundSelect = ({ __sround, sel }) => {
    sessionStorage.setItem('_path', JSON.stringify('holereport'));
    // console.log('handleRoundSelect');
    // console.log({ saving: { __sround } });
    setHighliteSel(sel);
    sessionStorage.setItem('__active_screen', JSON.stringify(sel));
    Object.keys(userQcQuickMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    Object.keys(tkdnMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    sessionStorage.setItem('__sround', JSON.stringify(__sround));
    //setOpenOne(false);
  };
  const handleQCHole = ({ sel, ...__qchole }) => {
    sessionStorage.setItem('_path', JSON.stringify('qualitycontrol'));
    // console.log('handleQCHole');
    //console.log({ __qchole });
    setHighliteSel(sel);
    sessionStorage.setItem('__active_screen', JSON.stringify(sel));
    Object.keys(userQcQuickMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    Object.keys(tkdnMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    sessionStorage.setItem('__qchole', JSON.stringify(__qchole));
  };
  const handleAdminClick = (sel) => {
    // console.log('handleAdminClick', { sel });
    sessionStorage.setItem('_path', JSON.stringify('adminshots'));
    sessionStorage.setItem('__active_screen', JSON.stringify(sel));
    setHighliteSel(sel);
    Object.keys(userQcQuickMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    Object.keys(tkdnMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    sessionStorage.setItem('__sround', JSON.stringify(0));
    setOpenOne(false);
    setOpenTwo(false);
    setOpenThree(false);
    setOpenFour(false);
  };
  const handleIssuesListClick = (sel, _issueIndex_) => {
    // console.log('handleAdminClick', { sel });
    sessionStorage.setItem('_path', JSON.stringify('issueslist'));
    sessionStorage.setItem('__active_screen', JSON.stringify(sel));
    setHighliteSel(sel);
    Object.keys(userQcQuickMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    Object.keys(tkdnMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    sessionStorage.setItem('__sround', JSON.stringify(0));
    sessionStorage.setItem('_issueIndex_', JSON.stringify(_issueIndex_));
  };
  const handleIBMClick = (sel) => {
    // console.log('handleAdminClick', { sel });
    sessionStorage.setItem('_path', JSON.stringify('ibmshots'));
    sessionStorage.setItem('__active_screen', JSON.stringify(sel));
    setHighliteSel(sel);
    Object.keys(userQcQuickMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    Object.keys(tkdnMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    sessionStorage.setItem('__sround', JSON.stringify(0));
    setOpenOne(false);
    setOpenTwo(false);
    setOpenThree(false);
    setOpenFour(false);
  };

  const handlePlayerShots = (sel, _issueIndex_) => {
    // console.log('handleAdminClick', { sel });
    sessionStorage.setItem('_path', JSON.stringify('Production/PlayerShots'));
    sessionStorage.setItem('__active_screen', JSON.stringify(sel));
    setHighliteSel(sel);
    Object.keys(userQcQuickMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    Object.keys(tkdnMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    sessionStorage.setItem('__sround', JSON.stringify(0));
    sessionStorage.setItem('_issueIndex_', JSON.stringify(_issueIndex_));
  };

  const toggleHoleThenMenuClick = (sel, key) => {
    if (sel === 'Dashboard') {
      sessionStorage.setItem('_path', JSON.stringify('dashboard'));
      setOpenFour(false);
    } else {
      sessionStorage.setItem('_path', JSON.stringify('qualitycontrol'));
    }
    if (key === '7' || key === '9') {
      setOpenFour(false);
    }
    if (sel === 'Round Summary') {
      sessionStorage.setItem('_path', JSON.stringify('roundreport'));
    }
    setHighliteSel(sel);
    sessionStorage.setItem('__active_screen', JSON.stringify(sel));
    sessionStorage.setItem('__sround', JSON.stringify(0));
    sessionStorage.setItem('__qchole', JSON.stringify(0));
    sessionStorage.setItem('_shotIdx_', JSON.stringify(0));
    const filterName = `_qcfilter_${key}`;
    Object.keys(userQcQuickMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    Object.keys(tkdnMap).forEach((key) => {
      const filterName = `_qcfilter_${key}`;
      sessionStorage.setItem(filterName, JSON.stringify(false));
    });
    if (key) {
      //console.log({ filterName, isTrue: true });
      sessionStorage.setItem(filterName, JSON.stringify(true));
    }
    setOpenOne(false);
    setOpenTwo(false);
    setOpenThree(false);

    //return onMenuClick;
  };
  const isSelectedCSS = { backgroundColor: 'rgba(255,255,255,0.08)' };
  return (
    <div className="MenuBlockID">
      <br></br>
      <Tooltip placement="right" title="Dashboard">
        <span>
          <DashboardMenuItem
            style={highliteSel === 'Dashboard' ? isSelectedCSS : {}}
            onClick={() => toggleHoleThenMenuClick('Dashboard')}
            sidebarIsOpen={open}
            leftIcon={
              <Tooltip placement="right" title="">
                <div>
                  <DashboardIcon />
                </div>
              </Tooltip>
            }
          />
        </span>
      </Tooltip>
      <Tooltip placement="right" title="Player's shot with some score information like par or total strokes">
          <MenuItemLink
            to={{
              pathname: `/Production/PlayerShots`,
              //replace: true,
            }}
            key="ProductionShots"
            style={highliteSel === 'Player Shots' ? isSelectedCSS : {}}
            primaryText="Player Shots"
            leftIcon={
              <Tooltip placement="right" title="">
                <div>
                  <SportsGolfIcon />
                </div>
              </Tooltip>
            }
            onClick={() => handlePlayerShots('Player Shots')}
            sidebarIsOpen={open}
          >
            {openOne ? <ExpandLess /> : <ExpandMore />}
          </MenuItemLink>
      </Tooltip>
      {!roles.includes('qc-user') &&
        [7].map((_shotIdx_) => {
          return (
            <MenuItemLink
              style={
                highliteSel === `${shotMap[_shotIdx_]}` ? isSelectedCSS : {}
              }
              key={`shot${_shotIdx_}`}
              leftIcon={
                <Tooltip placement="right" title="">
                  <div>
                    <ListAltOutlinedIcon />
                  </div>
                </Tooltip>
              }
              to={{
                pathname: '/Shots',
                //replace: true,
                //search: qs.stringify({ msg: `${shotMap[_shotIdx_]}` }),
              }}
              primaryText={`${shotMap[_shotIdx_]}`}
              onClick={() =>
                handleShotSelect(`${shotMap[_shotIdx_]}`, _shotIdx_)
              }
            />
          );
        })}
      {!roles.includes('qc-user') && (
        <Tooltip placement="right" title="Round Report">
          <MenuItemLink
            to={{
              pathname: `/RoundReport`,
              //replace: true,
            }}
            key="RoundSummary"
            style={highliteSel === 'Round Summary' ? isSelectedCSS : {}}
            primaryText="Round Summary"
            leftIcon={
              <Tooltip placement="right" title="">
                <div>
                  <LabelIcon />
                </div>
              </Tooltip>
            }
            onClick={() => toggleHoleThenMenuClick('Round Summary')}
            sidebarIsOpen={open}
          />
        </Tooltip>
      )}
      {!roles.includes('qc-user') && (
        <Tooltip placement="right" title="Hole Report">
          <MenuItemLink
            to={{
              pathname: `/HoleReport`,
              //replace: true,
            }}
            key="HoleSummary"
            style={highliteSel === 'Hole Summary' ? isSelectedCSS : {}}
            primaryText="Hole Summary"
            leftIcon={
              <Tooltip placement="right" title="">
                <div>
                  <Adjust />
                </div>
              </Tooltip>
            }
            onClick={() => handleClickOne('Hole Summary')}
            sidebarIsOpen={open}
          >
            {openOne ? <ExpandLess /> : <ExpandMore />}
          </MenuItemLink>
        </Tooltip>
      )}
      {!roles.includes('qc-user') && (
        <Collapse in={open && openOne} timeout="auto" unmountOnExit>
          <List style={styles.listItem} component="div" disablePadding>
            {Array.from(Array(4), (x, i) => 1 + i).map((__sround) => {
              return (
                <MenuItemLink
                  leftIcon={
                    <Tooltip placement="right" title="">
                      <div>
                        <Adjust />
                      </div>
                    </Tooltip>
                  }
                  style={
                    highliteSel === `Hole Summary, Round ${__sround}`
                      ? isSelectedCSS
                      : {}
                  }
                  to={{
                    pathname: '/HoleReport',
                    //replace: true,
                  }}
                  key={`round${__sround}`}
                  primaryText={'Round ' + String(__sround)}
                  onClick={() =>
                    handleRoundSelect({
                      sel: `Hole Summary, Round ${__sround}`,
                      __sround,
                    })
                  }
                />
              );
            })}
          </List>
        </Collapse>
      )}
      {!roles.includes('qc-user') && (
        <Tooltip placement="right" title="Takedown By Round">
          <MenuItemLink
            style={highliteSel === `Takedown By Round` ? isSelectedCSS : {}}
            to={{
              pathname: `/Shots`,
              //replace: true,
            }}
            key="TakeDownsByRound"
            primaryText="Takedown By Round"
            leftIcon={
              <Tooltip placement="right" title="">
                <div>
                  <Warning />
                </div>
              </Tooltip>
            }
            onClick={() => handleClickThree(`Takedown By Round`)}
            sidebarIsOpen={open}
          >
            {openThree ? <ExpandLess /> : <ExpandMore />}
          </MenuItemLink>
        </Tooltip>
      )}
      {!roles.includes('qc-user') && (
        <Collapse in={open && openThree} timeout="auto" unmountOnExit>
          <List style={styles.listItem} component="div" disablePadding>
            {Array.from(Array(4), (x, i) => 1 + i).map((__sround) => {
              const _shotIdx_ = 1;
              return (
                <MenuItemLink
                  style={
                    highliteSel === `TakeDowns, Round ${__sround}`
                      ? isSelectedCSS
                      : {}
                  }
                  key={`shot${_shotIdx_}_round${__sround}`}
                  leftIcon={
                    <Tooltip placement="right" title="">
                      <div>
                        <Warning />
                      </div>
                    </Tooltip>
                  }
                  to={{
                    pathname: '/Shots',
                    //replace: true,
                  }}
                  primaryText={`Round ${__sround}`}
                  onClick={() =>
                    handleTakeDownByRound(
                      `TakeDowns, Round ${__sround}`,
                      _shotIdx_,
                      __sround
                    )
                  }
                />
              );
            })}
          </List>
        </Collapse>
      )}
      
      <Tooltip placement="right" title="Holes">
        <MenuItemLink
          style={highliteSel === `Holes` ? isSelectedCSS : {}}
          to={{
            pathname: '/QualityControl',
            //replace: true
          }}
          key="holes"
          primaryText="Holes"
          leftIcon={
            <Tooltip placement="right" title="">
              <div>
                <GolfCourse />
              </div>
            </Tooltip>
          }
          onClick={() => handleClickTwo(`Holes`)}
          sidebarIsOpen={open}
        >
          {openTwo ? <ExpandLess /> : <ExpandMore />}
        </MenuItemLink>
      </Tooltip>
      <Collapse in={open && openTwo} timeout="auto" unmountOnExit>
        <List style={styles.listItem} component="div" disablePadding>
          {Array.from(Array(18), (x, i) => 1 + i).map((__qchole_) => {
            const __qchole = ('00' + __qchole_).slice(-2);
            return (
              <MenuItemLink
                leftIcon={
                  <Tooltip placement="right" title="">
                    <div>
                      <SportsGolf />
                    </div>
                  </Tooltip>
                }
                style={highliteSel === `Hole ${__qchole}` ? isSelectedCSS : {}}
                to={{
                  pathname: '/QualityControl',
                  //replace: true,
                  //state: { __qchole },
                  //search: qs.stringify({ __qchole }),
                  //search: qs.stringify({ msg: `Hole ${__qchole}` }),
                }}
                key={`hole${__qchole}`}
                primaryText={'Hole ' + String(__qchole)}
                onClick={() =>
                  handleQCHole({ sel: `Hole ${__qchole}`, __qchole })
                }
                //onClick={onMenuClick}
              />
            );
          })}
        </List>
      </Collapse>
      <Tooltip placement="right" title="QC">
        <MenuItemLink
          style={highliteSel === `QC` ? isSelectedCSS : {}}
          to={{
            pathname: '/QualityControl',
            //replace: true
          }}
          key="QC"
          primaryText="QC"
          leftIcon={
            <Tooltip placement="right" title="">
              <div>
                <QCHead />
              </div>
            </Tooltip>
          }
          onClick={() => handleClickFour(`QC`)}
          sidebarIsOpen={open}
        >
          {openFour ? <ExpandLess /> : <ExpandMore />}
        </MenuItemLink>
      </Tooltip>
      <Collapse in={open && openFour} timeout="auto" unmountOnExit>
        <List
          placement="right"
          style={styles.listItem}
          component="div"
          disablePadding
        >
          {Object.entries(userQcQuickMap).map(([key, value]) => (
            <Tooltip
              placement="right"
              key={value.description + 'tt'}
              title={value.description}
            >
              <MenuItemLink
                title={undefined}
                key={value.description}
                style={highliteSel === value.description ? isSelectedCSS : {}}
                to={{
                  pathname: `/QualityControl`,
                  //state: { __qchole: undefined },
                  //replace: true,
                  //search: qs.stringify({ msg: value.description }),
                }}
                primaryText={value.description}
                leftIcon={
                  <Tooltip placement="right" title="">
                    <div>
                      <QCHead />
                    </div>
                  </Tooltip>
                }
                onClick={() => toggleHoleThenMenuClick(value.description, key)}
                sidebarIsOpen={open}
              />
            </Tooltip>
          ))}
        </List>
      </Collapse>
      {Object.entries(tkdnMap).map(([key, value]) => (
        <Tooltip
          placement="right"
          key={value.description + 'tt'}
          title={value.description}
        >
          <MenuItemLink
            title={undefined}
            key={value.description}
            style={highliteSel === value.description ? isSelectedCSS : {}}
            to={{
              pathname: `/QualityControl`,
              //state: { __qchole: undefined },
              //replace: true,
              //search: qs.stringify({ msg: value.description }),
            }}
            primaryText={value.description}
            leftIcon={
              key === '7' ? (
                <Tooltip placement="right" title="">
                  <div>
                    <CheckCircleOutlineRounded />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip placement="right" title="">
                  <div>
                    <CheckCircleRounded />
                  </div>
                </Tooltip>
              )
            }
            onClick={() => toggleHoleThenMenuClick(value.description, key)}
            sidebarIsOpen={open}
          />
        </Tooltip>
      ))}
      {!roles.includes('qc-user') &&
        Array.from(Array(5), (x, i) => 2 + i).map((_shotIdx_) => {
          return (
            <MenuItemLink
              style={
                highliteSel === `${shotMap[_shotIdx_]}` ? isSelectedCSS : {}
              }
              key={`shot${_shotIdx_}`}
              leftIcon={
                <Tooltip placement="right" title="">
                  <div>
                    <ListAltOutlinedIcon />
                  </div>
                </Tooltip>
              }
              to={{
                pathname: '/Shots',
                //replace: true,
                //search: qs.stringify({ msg: `${shotMap[_shotIdx_]}` }),
              }}
              primaryText={`${shotMap[_shotIdx_]}`}
              onClick={() =>
                handleShotSelect(`${shotMap[_shotIdx_]}`, _shotIdx_)
              }
            />
          );
        })}
      {!roles.includes('qc-user') && (
        <MenuItemLink
          key="visualizations"
          style={highliteSel === 'Visualizations' ? isSelectedCSS : {}}
          leftIcon={
            <Tooltip placement="right" title="">
              <div>
                <AssessmentIcon />
              </div>
            </Tooltip>
          }
          to={{
            pathname: '/Visualizations',
            //replace: true,
            //search: qs.stringify({ msg: `adminshots` }),
          }}
          onClick={() => handleVizClick(`Visualizations`)}
          primaryText="Visualizations (Beta)"
        />
      )}
      {(roles.includes('3rdparty-user') || roles.includes('admin-user')) && (
        <MenuItemLink
          key="ibmShotList"
          style={highliteSel === 'IBM Timing' ? isSelectedCSS : {}}
          leftIcon={
            <Tooltip placement="right" title="">
              <div>
                <Computer />
              </div>
            </Tooltip>
          }
          to={{
            pathname: '/IBMShots',
            //replace: true,
            //search: qs.stringify({ msg: `adminshots` }),
          }}
          onClick={() => handleIBMClick(`IBM Timing`)}
          primaryText="IBM Timing"
        />
      )}
      {roles.includes('admin-user') && (
        <MenuItemLink
          key="adminShotList"
          style={highliteSel === 'AdminShots' ? isSelectedCSS : {}}
          leftIcon={
            <Tooltip placement="right" title="">
              <div>
                <Gavel />
              </div>
            </Tooltip>
          }
          to={{
            pathname: '/AdminShots',
            //replace: true,
            //search: qs.stringify({ msg: `adminshots` }),
          }}
          onClick={() => handleAdminClick(`AdminShots`)}
          primaryText="Admin"
        />
      )}
      {roles.includes('admin-user') && (
        Array.from(Array(2), (x, i) => i).map((_issueIndex_) => {
          return (
            <MenuItemLink
              key={`issues${_issueIndex_}`}
              style={highliteSel === issueMap[_issueIndex_] ? isSelectedCSS : {}}
              leftIcon={
              <Tooltip placement="right" title="">
                <div>
                  <WarningIcon />
                </div>
              </Tooltip>
              }
              to={{
                pathname: '/IssuesList',
                //replace: true,
                //search: qs.stringify({ msg: `adminshots` }),
                }}
                onClick={() => handleIssuesListClick(`${issueMap[_issueIndex_]}`, _issueIndex_)}
                primaryText={`${issueMap[_issueIndex_]}`}
            />
          )
        }) 
      )}
      {isXSmall && logout}
    </div>
  );
};

export default Menu;

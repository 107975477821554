import React, { useRef, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  SelectInput,
  TextInput,
  Filter,
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  useNotify,
  useRefresh,
  FunctionField,
  useRedirect,
  DatagridBody,
  CardActions, 
  ShowButton,
  ReferenceManyField,
  DateField,
  EditButton
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { 
  withStyles, 
  Button, 
  Accordion, 
  Card, 
  CardHeader, 
  CardContent,
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';

import NotesInput from './NotesInput';
import IssueList from './components/IssueList';
import CopyFilename from './components/CopyFilename';
import CopyRecordID from './components/CopyRecordID';
import ShotVersionHistory from './components/ShotVersionHistory';

import AssignToButton from './components/AssignToButton';

const toolbarStyles = {
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  };

const CustomToolbar = withStyles(toolbarStyles)((props) => (
    <Toolbar {...props}>
      {/* <Button onClick={props.toggleHistory}>{props.showHistory ? 'Hide' : 'Show'} Record History</Button>  */}
      <SaveButton label="SAVE" redirect="list" />
    </Toolbar>
  ));


export default function ShotEdit (props_) {
    const { onMount, onUnmount, basePath, ...props } = props_;

    const refresh = useRefresh();
    React.useEffect(() => {
      onMount && onMount();
      return () => {
        onUnmount && onUnmount();
      };
    }, [onMount, onUnmount]);
    const notify = useNotify();
    const [showHistory, setShowHistory] = useState(false);
    const toggleHistory = () => setShowHistory(!showHistory);
  
    const onSuccess = ({ data }) => {
      notify(
        'Update Successful!',
        'info',
        {
          smart_count: 1,
        },
        props.undoable
      );
  
      const doUnex = props.expandedid.current.unexpand;
      props.expandedid.current = {};
      doUnex && doUnex();
      refresh();
    };
  
    const onFailure = (error) => {
      notify(
        'Update Failed!',
        'info',
        {
          smart_count: 1,
        },
        props.undoable
      );
      refresh();
    };
  
    console.log("RECORD", props);
  
    const username = JSON.parse(localStorage.getItem('username') || '""');
    const userEmail = JSON.parse(localStorage.getItem('user-email') || '""');
    const qcEditBy = `${userEmail}`;
    const editDate = new Date().toISOString();
    return (
      <React.Fragment>
        <Edit
          onSuccess={onSuccess}
          onFailure={onFailure}
          {...props_}
          undoable={false}
          redirect="list"
          /* disable the app title change when shown */
          title=" "
        >
          <SimpleForm toolbar={<CustomToolbar showHistory={showHistory} toggleHistory={toggleHistory} />}>
  
            <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  flex: 1,
                }}
              >
                <div
                  style={{
                    paddingRight: '2ch',
                    flex: 1,
                    flexDirection: 'column',
                    display: 'flex',
                    width: 0,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      style={{
                        paddingRight: '2ch',
                        flex: 1
                      }}
                    >
                      <SelectInput
                        label="QC Status"
                        source="QcStatus"
                        style={{ width: '200px' }}
                        choices={[
                          { id: '-2', name: 'Unproducible' },
                          { id: '-1', name: 'Pass - Resent' },
                          { id: '0', name: 'Pass' },
                          { id: '1', name: 'Fail - no audio' },
                          { id: '2', name: 'Fail - spectator talking' },
                          { id: '3', name: 'Fail - audio sync' },
                          { id: '4', name: 'Fail - audio tech issue' },
                          { id: '5', name: 'Fail - other' },
                          { id: '6', name: 'Fail - Editorial' },
                          { id: '8', name: 'Fail - Hawk-Eye' },
                          //{ id: '7', name: 'Fail - Takedown' },
                          //{ id: '9', name: 'Takedown-Complete' },
                        ]}
                      />
                    </div>
                    <div
                      style={{
                        flex: 1
                      }}
                    >
                      <SelectInput
                        label="Takedown Status"
                        source="takedownStatus"
                        style={{ width: '200px' }}
                        choices={[
                          { id: '-1', name: 'No Takedown' },
                          { id: '0', name: 'Takedown' },
                          { id: '1', name: 'Takedown Complete' },
                        ]}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      flexDirection: 'row',
                    }}
                  >
                    <div style={{flex: 1, paddingRight: '2ch'}}>
                        <TextInput
                          disabled
                          label="Edit By"
                          source="editQcBy"
                          initialValue={qcEditBy}
                        />
                    </div>
                    <div style={{flex: 1}}>
                      <TextInput
                        disabled
                        label="Edit Time"
                        source="editAt"
                        initialValue={editDate}
                      />
                    </div>
                  </div>
                  
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      flexDirection: 'row', 
                    }}
                  >
                    <div style={{flex: 1, paddingRight: '2ch'}}>
                      <TextInput
                        disabled
                        label="Assigned To:"
                        source="AssignedTo"
                        style={{flex: 1}}
                      />
                    </div>
                    <div style={{flex: 1, display: 'flex', marginBottom: '3ch', marginTop: '0.5ch'}}>
                      <AssignToButton  record={props.record}/>
                    </div>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row',}}>
                    <div style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                      <CopyFilename record={props.record} />
                    </div>
                    <div style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                      <CopyRecordID record={props.record} />
                    </div>
                  </div>
                </div>
  
                
                <div 
                  style={{
                    paddingRight: '2ch',
                    flex: 1,
                    flexDirection: 'column',
                    display: 'flex',
                    width: 0,
                    
                  }}
                >
                  <div flex='1'>
                    <NotesInput
                      multiline
                      id="Notes"
                      label="QC Note"
                      resettable={false}
                      source="Notes"
                    />
                  </div>
                  <div flex='1'>
                    <NotesInput
                      multiline
                      id="HawkeyeNotes"
                      source="HawkeyeNotes"
                      label="Hawk-Eye Note"
                      resettable={false}
                    />
                  </div>
                </div>
                
                
                
                <div
                  style={{
                    width: '5%'
                  }}
                />
                <div
                  style={{
                    paddingRight: '2ch',
                    flex: 1,
                    flexDirection: 'column',
                    display: 'flex',
                    width: 0,
                  }}
                >
                  {<IssueList record={props.record}/>}
                </div> 
                  
              </div>
            
          </SimpleForm>
          
        </Edit>
        { showHistory && <ShotVersionHistory record={props.record} /> }
      </React.Fragment>
    );
  };
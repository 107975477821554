

import React, { useRef, useState, useEffect } from 'react';
import {
  List,
  Datagrid,
  // TextField,
  NumberField,
  SelectInput,
  TextInput,
  Filter,
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  useNotify,
  useRefresh,
  FunctionField,
  useRedirect,
  DatagridBody,
  BulkDeleteWithConfirmButton
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { 
  withStyles, 
  Button, 
  Accordion, 
  Card, 
  CardHeader, 
  CardContent,
  Divider,
  Fab,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddIcon from '@material-ui/icons/Add';

import NoRAStatusPicker from './dropdowns/no-ra/StatusPicker';

import axios from 'axios';
import _ from 'lodash';
const ESEH_API_URL = process.env.REACT_APP_ESEH_API_URL;
const FE_API_URL = `${process.env.REACT_APP_APIURL}:${process.env.REACT_APP_APIPORT}`


async function getIssueTypes() {
  try {
      let res = await axios.get(`${FE_API_URL}/dropdowns/3`);
      console.log('ISSUE TYPES', res.data);
      return res.data;
  } catch(err) {
      console.log('Error getting issue types:', err);
      return [
          {id: '1', status: 'Issue-type-1'}, 
          {id: '2', status: 'Issue-type-2'}, 
          {id: '3', status: 'Issue-type-3'}
  ];
  }
}

async function getIssueResolutionStatuses() {
  try {
      let res = await axios.get(`${FE_API_URL}/dropdowns/4`);
      console.log('Got Resolutions:', res);
      return res.data;
  } catch(err) {
      console.log('Error getting issue types:', err);
      return [
          {id: '1', status: 'Issue-type-1'}, 
          {id: '2', status: 'Issue-type-2'}, 
          {id: '3', status: 'Issue-type-3'}
  ];
  }
}

let issueTypes = [];
let issueTypeRef = {};
let resolutionStatuses = [];
let resolutionStatusRef = {};

async function updateIssueTypesAndResolutions() {
  try {
    issueTypes = await getIssueTypes();
    console.log('GOT ISSUE TYPES:', issueTypes)
    issueTypeRef = issueTypes.reduce((obj, issueType) => {
      obj[issueType.id] = issueType;
      return obj;
    }, {});

    resolutionStatuses = await getIssueResolutionStatuses();
    resolutionStatusRef = resolutionStatuses.reduce((obj, resStatus) => {
      obj[resStatus.id] = resStatus
      return obj;
    }, {});
    console.log('GOT ResStatusRef', resolutionStatusRef);
  } catch(err) {
    console.log('Error getting issueTypes.', err);
  }
};
updateIssueTypesAndResolutions();

function formatUser(str) {
  let arr = str.split('|');
  if (arr.length > 1) {
    let [slack, channel, user] = arr;
    return `Slack User - ${user}`;
  }
  return str;
}

const resolveIconRef ={
  Resolved: (<CheckCircleIcon style={{color: 'green', marginBottom: '-5px', marginRight: '10px',}}/>),
  Unresolvable: (<WarningIcon style={{marginRight: '10px', marginBottom: '-5px', color: 'yellow'}}/>),
  Unresolved: (<WarningIcon style={{marginRight: '10px', marginBottom: '-5px', color: 'yellow'}}/>),
  ['Re-approved']: (<CheckCircleIcon style={{color: 'green', marginBottom: '-5px', marginRight: '10px',}}/>)
}

function SingleIssue(props) {
  const { issue } = props;
  console.log('Resolutions in Issue:', issue, resolutionStatusRef);
  return (
    <Card variant="outlined">
      <CardContent>
        <div style={{display: 'flex'}}>

          <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
            <Typography variant="h6" color="textSecondary" gutterBottom>

              { issue.Resolution && resolutionStatusRef[issue.Resolution] && resolveIconRef[resolutionStatusRef[issue.Resolution].status] || <WarningIcon style={{marginRight: '10px', marginBottom: '-5px', color: 'yellow'}} /> }
              {issueTypeRef[issue.IssueType].status}
            </Typography>
            <Typography variant="body2" component="p">
              Issue: {issue.Issue}
            </Typography>
            <Typography variant="body2" component="p">
               Resolution Status: {issue.Resolution ? resolutionStatusRef[issue.Resolution].status : 'Unresolved'}
            </Typography>
            { (issue.ResolveMessage ? (
                <Typography variant="body2" component="p">
                  Resolve Message: {issue.ResolveMessage}
                </Typography>
              ) : null)
            }
          </div>

          <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
            <Typography variant="body2" component="p">
              Submitted by: {formatUser(issue.CreatedBy)}
            </Typography>
            <Typography variant="body2" component="p">
              Submitted at: {issue.createdAt || new Date().toISOString()}
            </Typography>
          </div>

        </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '2ch'}}>
          <ResolveIssueModal issue={issue} record={props.record} issueList={props.issueList} setIssueList={props.setIssueList} />
        </div>
      </CardContent>
    </Card>
  )

}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    color: 'white'
  },
}));
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function CreateIssueModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle());
  const [open, setOpen] = React.useState(false);
  const [issueMessage, setIssueMessage] = React.useState('');
  
  const userEmail = JSON.parse(localStorage.getItem('user-email') || '""');
  
  const handleChangeMessage = (evt) => {
    setIssueMessage(evt.target.value);
  }

  const handleOpen = () => { 
    // console.log('Opening Modal...');
    setOpen(true); 
  };
  const handleClose = () => { setOpen(false); };

  const handleSubmit = (evt) => {
    let newIssue = {
      Round: props.record.Round,
      PlayerID: props.record.PlayerID,
      Hole: props.record.Hole,
      Stroke: props.record.Stroke,
      CreatedBy: userEmail,
      IssueType: evt.target[0].value,
      Issue: evt.target[1].value,
    };

    (async function() {
      try {

        let response = await axios.post(`${ESEH_API_URL}/issues`, newIssue);
        // console.log('Post issue response', response);
        setOpen(false)
        props.setIssueList(props.issueList.concat(response.data));
      } catch(err) {

      }
    })();
    
  }
  let label = 'issue-type';
  const body = (
    <div style={modalStyle} className={classes.paper} >
      <Typography variant="h5" color="textSecondary" gutterBottom>
        Report an Issue:
      </Typography>
      <Typography variant="h6" color="textSecondary" gutterBottom>
        Record #{ props.record.RecordID }
      </Typography>
      <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
        <div style={{flex: 1, width: '100%'}}>
          <FormControl className={classes.formControl}>
            <InputLabel 
              id={`status-picker-${label}-label`} 
              htmlFor={label} 
              variant="filled" 
              >
              Issue Type
            </InputLabel>
            <NoRAStatusPicker 
              required 
              statusType={3} 
              label={label} 
              style={{marginBottom: '2ch', width: '100%'}}
              variant="filled"
              />
          </FormControl>
        </div>
        <div style={{flex: 1, width: '100%'}}>
          <FormControl className={classes.formControl}>
            <TextField 
              id="issue-message-field" 
              variant="filled"
              required
              multiline
              label={'Describe the issue'}
              rows={4}
              value={issueMessage}
              onChange={handleChangeMessage}
            />
          </FormControl>
        </div>
        
        <Button type="submit" value="Submit" >Submit</Button>
      </form>
    </div>
  );

  return (
    <div style={{display: 'inline-block'}}>
      <Tooltip title="Add Issue" aria-label="add-issue">
        <Fab size="small" color="primary" style={{marginLeft: '20px'}}>
          <AddIcon onClick={handleOpen}/>
        </Fab>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );

}

function ResolveIssueModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle());
  const [open, setOpen] = React.useState(false);
  const [resolutionMessage, setResolutionMessage] = React.useState(props.issue.ResolutionMessage);

  const userEmail = JSON.parse(localStorage.getItem('user-email') || '""');

  const handleOpen = () => { 
    // console.log('Opening Modal...');
    setOpen(true); 
  };
  const handleClose = () => { setOpen(false); };

  const handleChangeMessage = (evt) => {
    setResolutionMessage(evt.target.value);
  }

  const handleSubmit = (evt) => {
    let updatedIssue = {
      
      Round: props.issue.Round,
      PlayerID: props.issue.PlayerID,
      Hole: props.issue.Hole,
      Stroke: props.issue.Stroke,
      IssueType: props.issue.IssueType,
      Notes: props.issue.Notes,
      Resolution: evt.target[0].value,
      ResolveMessage: evt.target[1].value,
      ResolvedBy: userEmail
    };
    const { setIssueList, issueList } = props;
    // console.log('Submit Resolve:', evt, updatedIssue);
    (async function() {
      try {

        let response = await axios.patch(`${ESEH_API_URL}/issues/${props.issue.id}`, updatedIssue);
        // console.log('Update issue response', response);
        let updatedIssues = _.cloneDeep(issueList);
        updatedIssues = updatedIssues.map(i => {
          return i.id === props.issue.id ? Object.assign({}, i, updatedIssue) : i;
        })
        // console.log('Updated Issues:', updatedIssues, props);
        setIssueList(updatedIssues);
        setOpen(false)
      } catch(err) {

      }
    })();
    
  }

  let label = 'resolution-type';
  const body = (
    <div style={modalStyle} className={classes.paper} >
      <Typography variant="h5" color="textSecondary" gutterBottom>
        Resolve Issue #{ props.issue.id }
      </Typography>
      <Typography variant="h6" color="textSecondary" gutterBottom>
        Issue Type: 
        <WarningIcon style={{marginLeft: '10px', marginRight: '10px', marginBottom: '-5px', color: 'yellow'}} />
        {issueTypeRef[props.issue.IssueType].status}
      </Typography>
      <Typography variant="body2" component="p" color="textSecondary" style={{marginBottom: '10px'}}>
        Issue: {props.issue.Issue}
      </Typography>
      <form onSubmit={handleSubmit} style={{color: 'white', display: 'flex', flexDirection: 'column', width: '100%'}}>
        <FormControl className={classes.formControl}>
          <InputLabel 
            id={`status-picker-${label}-label`} 
            htmlFor={label} 
            variant="filled" 
            >
            Issue Type
          </InputLabel>
          <NoRAStatusPicker 
            required 
            statusType={4} 
            label={label} 
            style={{marginBottom: '2ch', width: '100%'}}
            variant="filled"
            />
          <TextField
            id="resolution-message-field"
            variant="filled"
            required
            multiline
            label="How has this been resolved?"
            rows={4}
            value={resolutionMessage}
            onChange={handleChangeMessage}
            />
        </FormControl>
        <Button 
        type="submit" 
        value="Submit" 
        >Submit</Button>
      </form>
    </div>
  );

  return (
    <div style={props.style}>
      <Button onClick={handleOpen}>
        Resolve
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

function IssueList(props) {

  const [issueList, setIssueList] = useState([]);

  let { record } = props;
  if (!record) {
    record = {};
  }
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => { 
    // console.log('Opening Modal...');
    setOpen(true); 
  };
  const handleClose = () => { setOpen(false); };

  useEffect(() => {
    (async () => {
      // console.log('Getting issues...', record);
      
      let response = await axios.get(`${ESEH_API_URL}/issues/byRecordID/${record.id}`);
      // console.log('Got Issues', response.data);
      setIssueList(response.data);
    })()
  }, []);


    return (
        <div style={{marginLeft: '10px'}}>
          <Typography variant="h6" color="textSecondary" gutterBottom>
            Issues
            
            <CreateIssueModal issueList={issueList} setIssueList={setIssueList} record={record}/>
            
          </Typography>
          {issueList.map((issue, idx) => <SingleIssue issueList={issueList} setIssueList={setIssueList} record={record} key={issue.id || idx} issue={issue}/>)}
        </div>
    )
}





export default IssueList;


/*

sample issue


CreatedBy: "slack-user|U01HMGMNME3|arr213"
FileID: null
Hole: 1
Issue: "ertyuiodfghjk"
IssueType: 2
PlayerID: 10423
RecordID: "r1_10423_1_2"
Round: 1
SlackChannelID: "C01QN2P7SBW"
SlackMessageID: "1616735750.000800"
SlackTeamID: "T01J2G0ULSF"
Status: 0
Stroke: 2
createdAt: "2021-03-26T05:15:50.000Z"
id: 25
updatedAt: "2021-03-26T05:15:50.000Z"


*/
import React from 'react';

import MyLayout from './MyLayout';
import { defaultTheme, defaultI18nProvider } from 'react-admin';
import { Admin, Resource, Title } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
//import useInterval from '@use-it/interval';
import ListIcon from '@material-ui/icons/List';
import Gavel from '@material-ui/icons/Gavel';
import WarningIcon from '@material-ui/icons/Warning';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { merge } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

import Computer from '@material-ui/icons/Computer';
import { Route } from 'react-router-dom';

import TWLogin from './TWLogin';
import MyLoginForm from './MyLoginForm';
import { AdminShotList } from './AdminShotList';
import { IBMShotList } from './IBMShotList';
import authProvider from './authProvider';
import { QualityControlList } from './QualityControl';
import { ShotList } from './ShotList';
import { RoundReportList } from './RoundReportList';
import { HoleReportList } from './HoleReportList';
import { IssueList } from './IssuesList';
import { PlayerShots } from './PlayerStats';
import Dashboard from './Dashboard';
import Visualizations from './Visualizations';
import './index.css';

import createPalette from '@material-ui/core/styles/createPalette';

import { stringify } from 'query-string';
import { fetchUtils } from 'ra-core';


//import { getData } from '../../../dbc';

//Set APIURL/APIPORT as shorter variables
//const apiUrl = 'https://localhost'; //process.env.REACT_APP_APIURL;
//const apiPort = '5000'; //process.env.REACT_APP_APIPORT;
const apiUrl = process.env.REACT_APP_APIURL;
const apiPort = process.env.REACT_APP_APIPORT;

const palette = createPalette(
  merge({}, defaultTheme.palette, {
    root: {
      backgroundColor: '#dfdfdf',
    },
    type: 'dark',
    primary: {
      main: '#dfdfdf',
    },
    secondary: {
      main: '#333',
    },
  })
);
const typography = {
  // Use the system font instead of the default Roboto font.
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Arial',
    'sans-serif',
  ].join(','),
};

const typographyBase = {
  fontFamily: typography.fontFamily,
  fontSize: typography.fontSize,
  fontStyle: typography.fontStyle,
  color: typography.color,
};

const typographyHeader = {
  ...typographyBase,
  fontWeight: typography.fontWeightBold,
  fontFamily: typography.fontFamilySecondary, // Use a dedicated font for the header
};

const typographyBody = {
  ...typographyBase,
  fontWeight: typography.fontWeightRegular,
  fontFamily: typography.fontFamily,
};

const rawTheme = {
  palette,
  typography: {
    ...typographyBase,
    h1: {
      ...typographyHeader,
      textTransform: 'uppercase',
      fontSize: '4rem',
    },
    // ... Put other title styles below
    body1: {
      ...typographyBody,
      fontSize: '1rem',
    },
  },

  overrides: {
    RaMenuItemLink: {
      root: {
        color: palette.primary.main,
      },

      active: {
        //borderLeftStyle: 'none',
        //borderRightColor: palette.secondary.main,
        //borderRightWidth: defaultTheme.spacing(0.5),
        //borderRightStyle: 'solid',
        //backgroundColor: palette.action.selected, // Defined in the default palette
        color: palette.primary.main,
        //fontWeight: typography.fontWeightBold,
      },
      visited: {
        color: palette.primary.main,
      },
    },
  },
};

//backgroundImage="/background.jpg"
const stylesLogin = {
  main: {
    background: '#1a1a1a',
  },
  //avatar: {
  //background: { TWLogo },
  //backgroundRepeat: 'no-repeat',
  //backgroundSize: 'contain',
  //  height: 0,
  //},
  //icon: { src: { TWLogo } },
};
//display: 'none'
const MyLoginFormImpl = withStyles({
  button: { background: '#F15922' },
})(MyLoginForm);

const MyLoginPage = (props) => (
  <TWLogin
    loginorm={<MyLoginFormImpl />}
    //icon={<Avatar src={TWLogo} />}
    {...props}
  />
);

let theme = createMuiTheme(merge({}, defaultTheme, rawTheme));
theme = responsiveFontSizes(theme);

async function getDataTable() {
  const data = await fetch('/qualitycontrol', {
    method: 'get',
  })
    .then((res) => res.text())
    .then((resText) => {
      //console.log('resText:', resText);
      return resText;
    });
  //console.log('data:', data);
  return data;
}

const messages = {
  en: englishMessages,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'en', {
  allowMissing: true,
});

let fullApiUrl = apiUrl + ':' + apiPort;
const dataProvider = jsonServerProvider(apiUrl + ':' + apiPort);

// Modification to jsonServerProvider to fix reference filters.
var __assign = (this && this.__assign) || function () {
  __assign = Object.assign || function(t) {
      for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i];
          for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
              t[p] = s[p];
      }
      return t;
  };
  return __assign.apply(this, arguments);
};
dataProvider.getManyReference = function (resource, params) {
  var _a;
  var _b = params.pagination, page = _b.page, perPage = _b.perPage;
  var _c = params.sort, field = _c.field, order = _c.order;
  var query = __assign(__assign({}, (_a = {}, _a[params.target] = params.id, _a._sort = field, _a._order = order, _a._start = (page - 1) * perPage, _a._end = page * perPage, _a), fetchUtils.flattenObject(params.filter)));
  var url = fullApiUrl + "/" + resource + "?" + stringify(query);
  const httpClient = fetchUtils.fetchJson;
  return httpClient(url).then(function (_a) {
      var headers = _a.headers, json = _a.json;
      if (!headers.has('x-total-count')) {
          throw new Error('The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?');
      }
      return {
          data: json,
          total: parseInt(headers.get('x-total-count').split('/').pop(), 10),
      };
  });
};
//console.log(apiUrl + ':' + apiPort);
//console.log(process.env);
//console.log({ 'Data Provider at': apiUrl + ':' + apiPort });
const App = () => {
  return (
    <Admin
      layout={MyLayout}
      theme={theme}
      loginPage={withStyles(stylesLogin)(MyLoginPage)}
      dashboard={Dashboard}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      customRoutes={[
        <Route path="/Visualizations" component={Visualizations} />,
      ]}
    >
      <Title title=" " />
      <Resource
        name="QualityControl"
        list={QualityControlList}
        icon={ListIcon}
        bulkActionButtons={false}
      />
      <Resource
        name="Shots"
        list={ShotList}
        icon={ListIcon}
        bulkActionButtons={false}
      />
      <Resource
        name="RoundReport"
        list={RoundReportList}
        icon={ListIcon}
        bulkActionButtons={false}
      />
      <Resource
        name="HoleReport"
        list={HoleReportList}
        icon={ListIcon}
        bulkActionButtons={false}
      />
      <Resource
        name="AdminShots"
        list={AdminShotList}
        icon={Gavel}
        bulkActionButtons={false}
      />
      <Resource
        name="IssuesList"
        list={IssueList}
        icon={WarningIcon}
        bulkActionButtons={false}
      />
      <Resource
        name="IBMShots"
        list={IBMShotList}
        icon={Computer}
        bulkActionButtons={false}
      />
      <Resource
        name="Production/PlayerShots"
        list={PlayerShots}
        icon={Computer}
        bulkActionButtons={false}
      />
    </Admin>
  );
};

export default App;

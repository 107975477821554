// in src/MyLayout.js
import React from 'react';
import { Layout } from 'react-admin';
import MyMenu from './Menu';
import UserLogo from './resources/tw_mark.png';

import { AppBar, UserMenu } from 'react-admin';
// import { withStyles } from '@material-ui/core/styles';
// import Avatar from '@material-ui/core/Avatar';
import DetailsIcon from '@material-ui/icons/Details';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import MyNotification from './MyNotification';

import Znapbar from './ZnapBar';

/*
const MyUserMenu = (props) => (
  <UserMenu {...props}>
    <MenuItemLink
      to="/configuration"
      primaryText="Configuration"
      leftIcon={<SettingsIcon />}
    />
  </UserMenu>
);
*/

const myCustomIconStyle = {
  avatar: {
    height: 30,
    width: 30,
  },
};

// src="https://marmelab.com/images/avatars/adrien.jpg"
// const MyCustomIcon = withStyles(myCustomIconStyle)(({ classes }) => {
//   return <Avatar className={classes.avatar} src={UserLogo} />;
// });

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: -10,
  },
  spacer: {
    flex: 1,
  },
  logo: {
    maxWidth: '40px',
    marginLeft: -35,
  },
});

const CustomAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar {...props} color="secondary">
      <Toolbar>
        <a href="https://www.thumbwar.com/">
          <img src={UserLogo} alt="logo" className={classes.logo} />
        </a>
      </Toolbar>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
      ></Typography>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
    </AppBar>
  );
};

const MyUserMenu = (props) => {
  return (
    <UserMenu {...props} leftIcon={<DetailsIcon />} appBar={CustomAppBar} />
  );
};
//MuiPaper-root MuiAppBar-root MuiAppBar-positionStatic MuiAppBar-colorSecondary MuiPaper-elevation4
//MuiPaper-root MuiAppBar-root MuiAppBar-positionFixed MuiAppBar-colorSecondary mui-fixed MuiPaper-elevation4
const MyAppBar = (props) => <AppBar {...props} userMenu={<MyUserMenu />} />;
const MyLayout = (props) => {
  return (
    <Layout
      {...props}
      notification={MyNotification}
      menu={MyMenu}
      appBar={Znapbar}
    />
  );
};
export default MyLayout;

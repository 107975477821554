import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  useRefresh,
} from 'react-admin';
import {
  ESEHPagination,
  defaultPerPage,
  useRecursiveTimeout,
  getRefreshMillis,
} from './ESEHParts';
import { MyListActions } from './MyListActions';
import Typography from '@material-ui/core/Typography';

export const RoundReportList = (props) => {
  const refresh = useRefresh();
  useRecursiveTimeout(() => refresh(), getRefreshMillis);
  const pageBanner = JSON.parse(
    sessionStorage.getItem('__active_screen') || '""'
  );
  return (
    <React.Fragment>
      <Typography
        style={{ position: 'absolute', transform: 'translateY(1em)' }}
        variant="h6"
        id={pageBanner}
      >
        {pageBanner}
      </Typography>
      <List
        {...props}
        actions={<MyListActions />}
        bulkActionButtons={false}
        perPage={defaultPerPage}
        pagination={<ESEHPagination />}
        sort={{ field: 'Round', order: 'ASC' }}
        title=" "
      >
        <Datagrid>
          <NumberField label="Round" source="Round" />
          <NumberField label="Strokes" source="TotalStrokes" />
          <NumberField label="Clipped" source="TotalFilesCreated" />
          <TextField label="Clipped %" source="TotalFilesCreatedPercent" />
          <NumberField label="Processed" source="TotalXsqComplete" />
          <TextField label="Processed %" source="TotalXsqCompletePercent" />
          <NumberField label="Posted" source="IBM Posted" />
          <TextField label="Posted %" source="IBM Posted Percent" />
          <NumberField label="Takedowns" source="Takedowns" />
          <TextField label="Average XSquare Time" source="AverageXsqProcessTime" />
          <TextField label="Average E2E Time" source="TimeShotsToIbm" />
          <TextField
            label="Average IBM Time"
            source="Avg-IBM-TotalTime"
          />
        </Datagrid>
      </List>
    </React.Fragment>
  );
};

import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  useRefresh,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';

import {
  ESEHPagination,
  defaultPerPage,
  useRecursiveTimeout,
  getRefreshMillis,
} from './ESEHParts';
import { MyListActions } from './MyListActions';

//<h1 style={{ marginBottom: 0 }}>Hole Report</h1>
export const HoleReportList = (props) => {
  const refresh = useRefresh();
  useRecursiveTimeout(() => refresh(), getRefreshMillis);
  const pageBanner = JSON.parse(
    sessionStorage.getItem('__active_screen') || '""'
  );
  let filterObj = {};
  const __sround = JSON.parse(sessionStorage.getItem('__sround') || '""');
  if (__sround) {
    filterObj['__sround'] = __sround;
  }
  // console.log({ __sround });

  const component = (
    <React.Fragment>
      <Typography
        style={{ position: 'absolute', transform: 'translateY(1em)' }}
        variant="h6"
        id={pageBanner}
      >
        {pageBanner}
      </Typography>
      <List
        actions={<MyListActions />}
        filter={filterObj}
        bulkActionButtons={false}
        perPage={defaultPerPage}
        pagination={<ESEHPagination />}
        title=" "
        sort={{ field: 'Hole', order: 'ASC' }}
        {...props}
      >
        <Datagrid>
          <NumberField label="Hole" source="Hole" />
          <NumberField label="Round" source="Round" />
          <NumberField label="Strokes" source="TotalStrokes" />
          <NumberField label="Clipped" source="TotalFilesCreated" />
          <TextField label="Clipped %" source="TotalFilesCreatedPercent" />
          <NumberField label="Processed" source="TotalXsqComplete" />
          <TextField label="Processed %" source="TotalXsqCompletePercent" />
          <NumberField label="Posted" source="IBM Posted" />
          <TextField label="Posted %" source="IBM Posted Percent" />
          <NumberField label="Takedowns" source="Takedowns" />
          <TextField label="Average XSquare Time" source="AverageXsqProcessTime" />
          <TextField label="Average E2E Time" source="TimeShotsToIbm" />
          <TextField
            label="Average IBM Time"
            source="Avg-IBM-TotalTime"
          />
        </Datagrid>
      </List>
    </React.Fragment>
  );

  return component;
};
